import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';
import { ShowErrorAction } from '../infra/errors';
import { SortDescriptor } from "@progress/kendo-data-query";
import { actionCreators as UserActions } from '~store/auth/user';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import CustomsDocumentsFields, { CustomsDocumentsFieldsType } from '~enums/fields/customsDocuments';
import ItemFields, { ItemFieldsType } from '~enums/fields/item';
import ConditionFields, { ConditionFieldsType } from '~enums/fields/itemCondition';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { Units } from '~enums/units';
import UnitConversionType from '~enums/unitConversionType';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import ItemLocationHistoryItem from '~models/actionLog/itemLocationHistoryItem';
import ItemPackageHistoryItem from '~models/actionLog/itemPackageHistoryItem';
import ActionLogItem from '~models/actionLog/actionLogItem';
import CustomsDocumentHistoryItem from '~models/customsDocumentHistory/customsDocumentHistoryItem';
import CustomsDocumentPatch from '~models/customsDocumentHistory/customsDocumentPatch';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler } from '~models/filters';
import ItemChild from '~models/item/itemChild';
import ItemCondition from '~models/itemCondition/itemCondition';
import ItemConditionPatch from '~models/itemCondition/itemConditionPatch';
import ItemDetailsData from '~models/item/itemDetailsData';
import ItemDetailsPatch from '~models/item/itemDetailsPatch';
import ItemUpdateResult from '~models/item/itemUpdateResult';
import ItemHistoryItem from '~models/itemHistory/itemHistoryItem';
import { PartyListItem } from '~models/party/partyListItem';

import { SplitItem } from '~components/modals/ItemDetails/DetailsTab/SplitItemModal';
import { AddPackageItem } from '~components/modals/ItemDetails/DetailsTab/AddPackagesModal';
import PackingTypeItem from '~models/packingType/packingTypeItem';
import { get8AMLocal } from '~src/utils/dateUtils';


export interface ItemDetailsChildren {
    items: Array<ItemChild>;
    count: number;
    skip: number;
    isLoading: boolean;
    deleteStatus: ServerOperationStatus;
}

export interface ItemDetailsTransactionHistory {
    historyItems: Array<ItemHistoryItem>;
    count: number;
    skip: number;
    isLoading: boolean;
}

export interface ItemDetailsLocationHistory {
    locations: Array<ItemLocationHistoryItem>;
    count: number;
    skip: number;
    isLoading: boolean;
}

export interface ItemDetailsPackageHistory {
    packages: Array<ItemPackageHistoryItem>;
    count: number;
    skip: number;
    isLoading: boolean;
}

export interface ItemDetailsActionLog {
    logs: Array<ActionLogItem>;
    count: number;
    skip: number;
    isLoading: boolean;
}

export interface ItemDetailsConditions {
    conditions: Array<ItemCondition>;
    isLoading: boolean;
    createStatus: ServerOperationStatus;
    updateStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

export interface ItemDetailsCustomsDocuments {
    documents: Array<CustomsDocumentHistoryItem>;
    count: number;
    skip: number;
    isLoading: boolean;
    createStatus: ServerOperationStatus;
    updateStatus: ServerOperationStatus;
}

export interface ItemDetailsState {
    data: ItemDetailsData;
    context: string;
    contextId: number;
    status: ServerOperationStatus;
    updateStatus: ServerOperationStatus;
    updatedField?: ItemFieldsType | ConditionFieldsType | CustomsDocumentsFieldsType;
    partsQtyUpdateStatus: ServerOperationStatus;
    piecesQtyUpdateStatus: ServerOperationStatus;
    splitStatus: ServerOperationStatus;
    addPackagesStatus: ServerOperationStatus;
    childItems: ItemDetailsChildren;
    transactionHistory: ItemDetailsTransactionHistory;
    locationHistory: ItemDetailsLocationHistory;
    packageHistory: ItemDetailsPackageHistory;
    actionLogs: ItemDetailsActionLog;
    conditions: ItemDetailsConditions;
    customsDocuments: ItemDetailsCustomsDocuments;
}

const EmptyData: ItemDetailsData = {
    uid: undefined,

    itemId: 0,
    uniqueId: '',
    transferredTo: null,
    transferredToRef: '',
    jobId: null,
    jobRef: '',
    parentId: 0,
    parentRef: '',
    purchaseOrderId: 0,
    purchaseOrderRef: '',
    clientFileUniqueId: '',
    lineItemNumber: 0,
    partNumber: 0,
    partsQty: 0,
    piecesQty: 0,
    dealerId: 0,
    dealerRef: '',
    description: '',

    condition: '',
    vendorPart: '',
    sidemark: '',
    lot: '',
    tag: '',
    category: '',

    packageId: null,
    packageRef: '',
    packageLabelNr: null,
    packagePackageType: '',
    packageExtDepth: 0,
    packageExtWidth: 0,
    packageExtHeight: 0,

    packageType: '',
    pbo: false,
    packageDepth: 0,
    packageWidth: 0,
    packageHeight: 0,

    artist: '',
    title: '',
    materials: '',
    circa: '',
    countryOfOrigin: '',

    depth: 0,
    width: 0,
    height: 0,
    volume: 0,
    grossVolume: 0,
    weight: 0,
    grossWeight: 0,

    value: 0,
    valuationCurrency: '',
    vat: 0,
    amountToPay: 0,

    locationId: null,
    locationRef: '',
    lastScanDate: null,

    transportMode: '',
    room: '',
    handlingType: '',

    lengthUnits: '',
    volumeUnits: '',
    weightUnits: '',

    bonded: false,
    cites: false,
    ta: false,
    toCrate: false,
    exportLicenseRequired: false,

    collectionDate: null,
    packingDate: null,
    receivedDate: null,
    releaseDate: null,
    deliveryDate: null,
    isCollected: false,
    isPacked: false,
    isReceived: false,
    isReleased: false,
    isDelivered: false,

    comments: '',
    registarNotes: '',

    handlingStatus: '',
    handlingStatusDate: null,
    inCare: false,
    split: false,
    assemble: false,
    customsStatus: '',
    customsStatusDate: null,
    customsEntryNumber: '',
    disposition: '',

    shippingCost: 0,
    insurancePremium: 0,
    hsCode: '',
    insuredByClient: false,
    shippingTerms: '',
    shippingCostRef: '',

    photoShootDate: null,
    shotBy: '',
    isCatalogued: false,

    vatonHammerPrice: 0,
    vatrateOnHammerPrice: 0,
    vatonBuyerPremium: 0,
    vatrateOnBuyerPremium: 0,
    artistRoyalty: 0,
    hammerPrice: 0,
    buyerPremium: 0,
    ownerId: null,
    ownerRef: '',
    ownerType: '',
    buyerId: null,
    buyerRef: '',
    buyerType: '',
    sellerId: null,
    sellerRef: '',
    sellerType: '',

    movement: '',
    accessories: '',

    transactionIds: [],

    canEdit: false,

    previousItemId: undefined,
    nextItemId: undefined,
};

export type ItemDetailsStateProps = ItemDetailsState;
export type ItemDetailsOutsideDispatchProps = typeof outsideActionCreators;
export type ItemDetailsInsideDispatchProps = typeof insideActionCreators;

// -----------------
// ---- ACTIONS ----
// -----------------

interface ItemDetailsOpenAction {
    type: 'ITEM_DETAILS_OPEN';
}
interface ItemDetailsRequestAction {
    type: 'ITEM_DETAILS_REQUEST';
    context: string;
    contextId: number;
}
interface ItemDetailsErrorAction {
    type: 'ITEM_DETAILS_REQUEST_ERROR';
}
interface ItemDetailsInitAction {
    type: 'ITEM_DETAILS_INIT';
    data: ItemDetailsData;
}
export interface ItemDetailsUpdateDataAction {
    type: 'ITEM_DETAILS_UPDATE_DATA';
    data: ItemDetailsData;
}
interface ItemDetailsUpdateDataRequestAction {
    type: 'ITEM_DETAILS_UPDATE_DATA_REQUEST';
    field: ItemFieldsType;
}
interface ItemDetailsUpdateDataErrorAction {
    type: 'ITEM_DETAILS_UPDATE_DATA_ERROR';
}
interface ConvertVolumeRequestAction {
    type: 'ITEM_DETAILS_CONVERT_VOLUME_REQUEST';
}
interface ConvertVolumeResultAction {
    type: 'ITEM_DETAILS_CONVERT_VOLUME_RECEIVE';
    volume: number;
}
interface ConvertVolumeErrorAction {
    type: 'ITEM_DETAILS_CONVERT_VOLUME_ERROR';
}
interface ConvertGrossVolumeRequestAction {
    type: 'ITEM_DETAILS_CONVERT_GROSS_VOLUME_REQUEST';
}
interface ConvertGrossVolumeResultAction {
    type: 'ITEM_DETAILS_CONVERT_GROSS_VOLUME_RECEIVE';
    volume: number;
}
interface ConvertGrossVolumeErrorAction {
    type: 'ITEM_DETAILS_CONVERT_GROSS_VOLUME_ERROR';
}

interface PartsQtyRequest {
    type: 'ITEM_DETAILS_UPDATE_PARTSQTY_REQUEST';
}
interface PartsQtyReceive {
    type: 'ITEM_DETAILS_UPDATE_PARTSQTY_RECEIVE';
    partsQty: number;
    childrenValues: Array<{itemId: number, partsQty: number}>;
}
interface PartsQtyError {
    type: 'ITEM_DETAILS_UPDATE_PARTSQTY_ERROR';
}

interface PiecesQtyRequest {
    type: 'ITEM_DETAILS_UPDATE_PIECESQTY_REQUEST';
}
interface PiecesQtyReceive {
    type: 'ITEM_DETAILS_UPDATE_PIECESQTY_RECEIVE';
    piecesQty: number;
    childrenValues: Array<{itemId: number, piecesQty: number}>;
}
interface PiecesQtyError {
    type: 'ITEM_DETAILS_UPDATE_PIECESQTY_ERROR';
}

interface ItemDetailsCloseAction {
    type: 'ITEM_DETAILS_CLOSE';
}

interface ChildrenItemsRequestAction {
    type: 'ITEM_DETAILS_CHILDREN_REQUEST';
    skip: number;
}
interface ChildrenItemsReceiveAction {
    type: 'ITEM_DETAILS_CHILDREN_RECEIVE';
    items: Array<ItemChild>;
    count: number;
}
interface ChildrenItemsErrorAction {
    type: 'ITEM_DETAILS_CHILDREN_ERROR';
}
interface ChildrenItemsDeleteStartAction {
    type: "ITEM_DETAILS_CHILDREN_DELETE_START";
}
interface ChildrenItemsDeleteSendAction {
    type: "ITEM_DETAILS_CHILDREN_DELETE_SEND";
}
interface ChildrenItemsDeleteSuccessAction {
    type: "ITEM_DETAILS_CHILDREN_DELETE_SUCCESS";
    ids: Array<number>;
}
interface ChildrenItemsDeleteCancelAction {
    type: "ITEM_DETAILS_CHILDREN_DELETE_CANCEL";
}
interface ChildrenItemsDeleteErrorAction {
    type: "ITEM_DETAILS_CHILDREN_DELETE_ERROR";
}

interface TransactionHistoryRequestAction {
    type: 'ITEM_DETAILS_TRANSACTION_HISTORY_REQUEST';
    skip: number;
}
interface TransactionHistoryReceiveAction {
    type: 'ITEM_DETAILS_TRANSACTION_HISTORY_RECEIVE';
    histories: Array<ItemHistoryItem>;
    count: number;
}
interface TransactionHistoryErrorAction {
    type: 'ITEM_DETAILS_TRANSACTION_HISTORY_ERROR';
}

interface LocationHistoryRequestAction {
    type: 'ITEM_DETAILS_LOCATION_HISTORY_REQUEST';
    skip: number;
}
interface LocationHistoryReceiveAction {
    type: 'ITEM_DETAILS_LOCATION_HISTORY_RECEIVE';
    histories: Array<ItemLocationHistoryItem>;
    count: number;
}
interface LocationHistoryErrorAction {
    type: 'ITEM_DETAILS_LOCATION_HISTORY_ERROR';
}

interface PackagesHistoryRequestAction {
    type: 'ITEM_DETAILS_PACKAGE_HISTORY_REQUEST';
    skip: number;
}
interface PackagesHistoryReceiveAction {
    type: 'ITEM_DETAILS_PACKAGE_HISTORY_RECEIVE';
    histories: Array<ItemPackageHistoryItem>;
    count: number;
}
interface PackagesHistoryErrorAction {
    type: 'ITEM_DETAILS_PACKAGE_HISTORY_ERROR';
}

interface ActionLogRequestAction {
    type: 'ITEM_DETAILS_ACTION_LOG_REQUEST';
    skip: number;
}
interface ActionLogReceiveAction {
    type: 'ITEM_DETAILS_ACTION_LOG_RECEIVE';
    logs: Array<ActionLogItem>;
    count: number;
}
interface ActionLogErrorAction {
    type: 'ITEM_DETAILS_ACTION_LOG_ERROR';
}

interface ConditionsRequestAction {
    type: 'ITEM_DETAILS_CONDITIONS_REQUEST';
}
interface ConditionsReceiveAction {
    type: 'ITEM_DETAILS_CONDITIONS_RECEIVE';
    conditions: Array<ItemCondition>;
}
interface ConditionsErrorAction {
    type: 'ITEM_DETAILS_CONDITIONS_ERROR';
}
interface ConditionsUpdateDataAction {
    type: 'ITEM_DETAILS_CONDITIONS_UPDATE_DATA';
    data: ItemCondition;
}
interface ConditionsUpdateDataRequestAction {
    type: 'ITEM_DETAILS_CONDITIONS_UPDATE_DATA_REQUEST';
    field: ConditionFieldsType;
}
interface ConditionsUpdateDataErrorAction {
    type: 'ITEM_DETAILS_CONDITIONS_UPDATE_DATA_ERROR';
}
interface ConditionsCreateRequest {
    type: 'ITEM_DETAILS_CONDITIONS_CREATE_REQUEST';
}
interface ConditionsCreateSuccess {
    type: 'ITEM_DETAILS_CONDITIONS_CREATE_SUCCESS';
    entity: ItemCondition;
}
interface ConditionsCreateError {
    type: 'ITEM_DETAILS_CONDITIONS_CREATE_ERROR';
}
interface ConditionsDeleteRequest {
    type: 'ITEM_DETAILS_CONDITIONS_DELETE_REQUEST';
}
interface ConditionsDeleteSuccess {
    type: 'ITEM_DETAILS_CONDITIONS_DELETE_SUCCESS';
    deletedId: number;
}
interface ConditionsDeleteError {
    type: 'ITEM_DETAILS_CONDITIONS_DELETE_ERROR';
}

interface CustomsDocumentsRequestAction {
    type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_REQUEST';
    skip: number;
}
interface CustomsDocumentsReceiveAction {
    type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_RECEIVE';
    documents: Array<CustomsDocumentHistoryItem>;
    count: number;
}
interface CustomsDocumentsErrorAction {
    type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_ERROR';
}
interface CustomsDocumentsUpdateDataAction {
    type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_UPDATE_DATA';
    data: CustomsDocumentHistoryItem;
}
interface CustomsDocumentsUpdateDataRequestAction {
    type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_UPDATE_DATA_REQUEST';
    field: CustomsDocumentsFieldsType;
}
interface CustomsDocumentsUpdateDataErrorAction {
    type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_UPDATE_DATA_ERROR';
}
interface CustomsDocumentsCreateRequest {
    type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_CREATE_REQUEST';
}
interface CustomsDocumentsCreateSuccess {
    type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_CREATE_SUCCESS';
    entity: CustomsDocumentHistoryItem;
}
interface CustomsDocumentsCreateError {
    type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_CREATE_ERROR';
}

interface ItemSplitRequest {
    type: 'ITEM_DETAILS_SPLIT_REQUEST';
}
interface ItemSplitSuccess {
    type: 'ITEM_DETAILS_SPLIT_SUCCESS';
    partsQty: number;
    piecesQty: number;
}
interface ItemSplitError {
    type: 'ITEM_DETAILS_SPLIT_ERROR';
}

interface ItemAddPackagesRequest {
    type: 'ITEM_DETAILS_ADDPACKAGES_REQUEST';
}
interface ItemAddPackagesSuccess {
    type: 'ITEM_DETAILS_ADDPACKAGES_SUCCESS';
    partsQty: number;
    piecesQty: number;
}
interface ItemAddPackagesError {
    type: 'ITEM_DETAILS_ADDPACKAGES_ERROR';
}

type KnownAction = ItemDetailsOpenAction | ItemDetailsRequestAction | ItemDetailsErrorAction
    | ItemDetailsInitAction
    | ItemDetailsUpdateDataAction | ItemDetailsUpdateDataRequestAction | ItemDetailsUpdateDataErrorAction
    | ConvertVolumeRequestAction | ConvertVolumeResultAction | ConvertVolumeErrorAction
    | ConvertGrossVolumeRequestAction | ConvertGrossVolumeResultAction | ConvertGrossVolumeErrorAction
    | PartsQtyRequest | PartsQtyReceive | PartsQtyError
    | PiecesQtyRequest | PiecesQtyReceive | PiecesQtyError
    | ItemDetailsCloseAction

    | ChildrenItemsRequestAction | ChildrenItemsReceiveAction | ChildrenItemsErrorAction
    | ChildrenItemsDeleteStartAction | ChildrenItemsDeleteSendAction | ChildrenItemsDeleteSuccessAction | ChildrenItemsDeleteCancelAction | ChildrenItemsDeleteErrorAction

    | TransactionHistoryRequestAction | TransactionHistoryReceiveAction | TransactionHistoryErrorAction

    | LocationHistoryRequestAction | LocationHistoryReceiveAction | LocationHistoryErrorAction

    | PackagesHistoryRequestAction | PackagesHistoryReceiveAction | PackagesHistoryErrorAction

    | ActionLogRequestAction | ActionLogReceiveAction | ActionLogErrorAction

    | ConditionsRequestAction | ConditionsReceiveAction | ConditionsErrorAction
    | ConditionsUpdateDataAction | ConditionsUpdateDataRequestAction | ConditionsUpdateDataErrorAction
    | ConditionsCreateRequest | ConditionsCreateSuccess | ConditionsCreateError
    | ConditionsDeleteRequest | ConditionsDeleteSuccess | ConditionsDeleteError

    | CustomsDocumentsRequestAction | CustomsDocumentsReceiveAction | CustomsDocumentsErrorAction
    | CustomsDocumentsUpdateDataAction | CustomsDocumentsUpdateDataRequestAction | CustomsDocumentsUpdateDataErrorAction
    | CustomsDocumentsCreateRequest | CustomsDocumentsCreateSuccess | CustomsDocumentsCreateError

    | ItemSplitRequest | ItemSplitSuccess | ItemSplitError
    | ItemAddPackagesRequest | ItemAddPackagesSuccess | ItemAddPackagesError

    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;

let detailsAbortController = new AbortController();

const buildPatch = (field: ItemFieldsType, value: any, context: string, contextId: number) => {
    const patchModel = {
        field: field,
        context: context,
        contextId: contextId,
    } as ItemDetailsPatch;

    switch (field) {
        case ItemFields.UniqueId:
            patchModel.uniqueId = value as string;
            break;
        case ItemFields.PartsQty:
            patchModel.partsQty = value as number;
            break;
        case ItemFields.PiecesQty:
            patchModel.piecesQty = value as number;
            break;
        case ItemFields.Description:
            patchModel.description = value as string;
            break;

        case ItemFields.PackingType:
            patchModel.packingType = value as string;
            break;
        case ItemFields.PBO:
            patchModel.PBO = value as boolean;
            break;
        case ItemFields.PackageDepth:
            patchModel.packageDepth = value as number;
            break;
        case ItemFields.PackageWidth:
            patchModel.packageWidth = value as number;
            break;
        case ItemFields.PackageHeight:
            patchModel.packageHeight = value as number;
            break;

        case ItemFields.Artist:
            patchModel.artist = value as string;
            break;
        case ItemFields.Title:
            patchModel.title = value as string;
            break;
        case ItemFields.Materials:
            patchModel.materials = value as string;
            break;
        case ItemFields.Circa:
            patchModel.circa = value as string;
            break;
        case ItemFields.CountryOfOrigin:
            patchModel.countryOfOrigin = value as string;
            break;

        case ItemFields.Depth:
            patchModel.depth = value as number;
            break;
        case ItemFields.Width:
            patchModel.width = value as number;
            break;
        case ItemFields.Height:
            patchModel.height = value as number;
            break;
        case ItemFields.Volume:
            patchModel.volume = value as number;
            break;
        case ItemFields.GrossVolume:
            patchModel.grossVolume = value as number;
            break;
        case ItemFields.Weight:
            patchModel.weight = value as number;
            break;
        case ItemFields.GrossWeight:
            patchModel.grossWeight = value as number;
            break;

        case ItemFields.Value:
            patchModel.value = value as number;
            break;
        case ItemFields.Currency:
            patchModel.valuationCurrency = value as string;
            break;
        case ItemFields.VAT:
            patchModel.VAT = value as number;
            break;
        case ItemFields.AmountToPay:
            patchModel.amountToPay = value as number;
            break;

        case ItemFields.Condition:
            patchModel.condition = value as string;
            break;
        case ItemFields.VendorPart:
            patchModel.vendorPart = value as string;
            break;
        case ItemFields.Sidemark:
            patchModel.sidemark = value as string;
            break;
        case ItemFields.Lot:
            patchModel.lot = value as string;
            break;
        case ItemFields.Tag:
            patchModel.tag = value as string;
            break;
        case ItemFields.Category:
            patchModel.category = value as string;
            break;

        case ItemFields.Location:
            patchModel.locationId = value as number | null;
            if (!!patchModel.locationId) {
                const now = new Date();
                patchModel.lastScanDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            }
            break;
        case ItemFields.LastScanDate:
            patchModel.lastScanDate = value as Date | null;
            break;
        case ItemFields.TransportMode:
            patchModel.transportMode = value as string;
            break;
        case ItemFields.Room:
            patchModel.room = value as string;
            break;
        case ItemFields.HandlingType:
            patchModel.handlingType = value as string;
            break;

        case ItemFields.LengthUnits:
            patchModel.lengthUnits = value as string;
            break;
        case ItemFields.VolumeUnits:
            patchModel.volumeUnits = value as string;
            break;
        case ItemFields.WeightUnits:
            patchModel.weightUnits = value as string;
            break;

        case ItemFields.Bonded:
            patchModel.bonded = value as boolean;
            break;
        case ItemFields.CITES:
            patchModel.CITES = value as boolean;
            break;
        case ItemFields.TA:
            patchModel.TA = value as boolean;
            break;
        case ItemFields.InCrate:
            patchModel.inCrate = value as boolean;
            break;
        case ItemFields.ExportLicenseRequired:
            patchModel.exportLicenseRequired = value as boolean;
            break;

        case ItemFields.CollectionDate:
            if (value !== null) {
                const selectedDate = value as Date;

                patchModel.collectionDate = get8AMLocal(selectedDate);
            } else {
                patchModel.collectionDate = value as Date;
            }
            break;
        case ItemFields.CollectionDateDone:
            patchModel.collectionDone = value as boolean;
            break;
        case ItemFields.PackingDate:
            if (value !== null) {
                const selectedDate = value as Date;

                patchModel.packingDate = get8AMLocal(selectedDate);
            } else {
                patchModel.packingDate = value as Date;
            }
            break;
        case ItemFields.PackingDateDone:
            patchModel.packingDone = value as boolean;
            break;
        case ItemFields.ReceivedDate:
            if (value !== null) {
                const selectedDate = value as Date;

                patchModel.receivedDate = get8AMLocal(selectedDate);
            } else {
                patchModel.receivedDate = value as Date;
            }
            break;
        case ItemFields.ReceivedDateDone:
            patchModel.receivedDone = value as boolean;
            break;
        case ItemFields.ReleaseDate:
            if (value !== null) {
                const selectedDate = value as Date;

                patchModel.releaseDate = get8AMLocal(selectedDate);
            } else {
                patchModel.releaseDate = value as Date;
            }
            break;
        case ItemFields.ReleaseDateDone:
            patchModel.releaseDone = value as boolean;
            break;
        case ItemFields.DeliveryDate:
            if (value !== null) {
                const selectedDate = value as Date;

                patchModel.deliveryDate = get8AMLocal(selectedDate);
            } else {
                patchModel.deliveryDate = value as Date;
            }
            break;
        case ItemFields.DeliveryDateDone:
            patchModel.deliveryDone = value as boolean;
            break;

        case ItemFields.Comments:
            patchModel.comments = value as string;
            break;
        case ItemFields.Registar:
            patchModel.registrarNotes = value as string;
            break;

        case ItemFields.HammerPrice:
            patchModel.hammerPrice = value as number;
            break;
        case ItemFields.BuyerPremium:
            patchModel.buyerPremium = value as number;
            break;
        case ItemFields.Owner:
            patchModel.ownerId = value as number;
            break;
        case ItemFields.Buyer:
            patchModel.buyerId = value as number;
            break;
        case ItemFields.Seller:
            patchModel.sellerId = value as number;
            break;

        case ItemFields.InCare:
            patchModel.inCare = value as boolean;
            break;
        case ItemFields.Assemble:
            patchModel.assemble = value as boolean;
            break;
        case ItemFields.CustomsStatus:
            patchModel.customsStatus = value as string;
            break;
        case ItemFields.CustomsStatusDate:
            patchModel.customsStatusDate = value as Date;
            break;
        case ItemFields.CustomsEntryNumber:
            patchModel.customsEntryNumber = value as string;
            break;
        case ItemFields.Disposition:
            patchModel.disposition = value as string;
            break;

        case ItemFields.ShippingCost:
            patchModel.shippingCost = value as number;
            break;
        case ItemFields.InsurancePremium:
            patchModel.insurancePremium = value as number;
            break;
        case ItemFields.HSCode:
            patchModel.HSCode = value as string;
            break;
        case ItemFields.InsuredByClient:
            patchModel.insuredByClient = value as boolean;
            break;
        case ItemFields.ShippingTerms:
            patchModel.shippingTerms = value as string;
            break;

        case ItemFields.PhotoShootDate:
            patchModel.photoShootDate = value as Date;
            break;
        case ItemFields.ShotBy:
            patchModel.shotBy = value as string;
            break;
        case ItemFields.IsCatalogued:
            patchModel.isCatalogued = value as boolean;
            break;

        case ItemFields.VatOnHammerPrice:
            patchModel.vatonHammerPrice = value as number;
            break;
        case ItemFields.VatRateOnHammer:
            patchModel.vatrateOnHammerPrice = value as number;
            break;
        case ItemFields.VatOnBuyerPremium:
            patchModel.vatonBuyerPremium = value as number;
            break;
        case ItemFields.VatRateOnBuyerPremium:
            patchModel.vatrateOnBuyerPremium = value as number;
            break;
        case ItemFields.ArtistRoyalty:
            patchModel.artistRoyalty = value as number;
            break;

        case ItemFields.Movement:
            patchModel.movement = value as string;
            break;
        case ItemFields.Accessories:
            patchModel.accessories = value as string;
            break;

        case ItemFields.TransferredTo:
            patchModel.transferredTo = null;
            break;
    }

    return patchModel;
};

const buildDocumentPatch = (field: CustomsDocumentsFieldsType, value: any) => {
    const patchModel = {field: field} as CustomsDocumentPatch;

    switch (field) {
        case CustomsDocumentsFields.JobFile:
            patchModel.fileName = value as string;
            break;
        case CustomsDocumentsFields.ItemNr:
            patchModel.itemNr = value as string;
            break;
        case CustomsDocumentsFields.DocNr:
            patchModel.docNr = value as string;
            break;
        case CustomsDocumentsFields.DocumentType:
            patchModel.docType = value as string;
            break;
        case CustomsDocumentsFields.CreationDate:
            if (value !== null) {
                const selectedDate = value as Date;

                patchModel.createdOn = get8AMLocal(selectedDate);
            } else {
                patchModel.createdOn = value as Date;
            }
            break;
        case CustomsDocumentsFields.ExpiryDate:
            if (value !== null) {
                const selectedDate = value as Date;

                patchModel.expiryDate = get8AMLocal(selectedDate);
            } else {
                patchModel.expiryDate = value as Date;
            }
            break;
        case CustomsDocumentsFields.PrevStatus:
            patchModel.prevStatus = value as string;
            break;
        case CustomsDocumentsFields.CurrentStatus:
            patchModel.currentStatus = value as string;
            break;
        case CustomsDocumentsFields.TransitDocNr:
            patchModel.transferDocNr = value as string;
            break;
        case CustomsDocumentsFields.DocIssuedBy:
            patchModel.issuedBy = value as string;
            break;
        case CustomsDocumentsFields.AFC:
            patchModel.afc = value as boolean;
            break;
        case CustomsDocumentsFields.CustomsOfficeLocationDeparture:
            patchModel.customsOfficeDeparture = value as string;
            break;
        case CustomsDocumentsFields.CountryOfDeparture:
            patchModel.countryOfOrigin = value as string;
            break;
        case CustomsDocumentsFields.CustomsOfficeLocationDestination:
            patchModel.customsOfficeArrival = value as string;
            break;
        case CustomsDocumentsFields.CountryOfDestination:
            patchModel.countryOfDestination = value as string;
            break;
        case CustomsDocumentsFields.ReplacedDocumentNr:
            patchModel.docReplaced = value as string;
            break;
        case CustomsDocumentsFields.ReplacedDocumentDate:
            if (value !== null) {
                const selectedDate = value as Date;

                patchModel.docReplacedDate = get8AMLocal(selectedDate);
            } else {
                patchModel.docReplacedDate = value as Date;
            }
            break;
        case CustomsDocumentsFields.TempExtensionNr:
            patchModel.tempExtensionNr = value as number;
            break;
        case CustomsDocumentsFields.TempDocSentTo:
            patchModel.tempDocSentTo = value as string;
            break;
        case CustomsDocumentsFields.ReceivedFrom:
            patchModel.receivedFromId = value as number;
            break;
        case CustomsDocumentsFields.ReleasedTo:
            patchModel.releasedToId = value as number;
            break;
    }

    return patchModel;
};

const buildConditionPatch = (field: ConditionFieldsType, value: any) => {
    const patchModel = {field: field} as ItemConditionPatch;

    switch (field) {
        case ConditionFields.Position:
            patchModel.conditionPosition = value as number;
            break;
        case ConditionFields.ConditionType:
            patchModel.conditionType = value as string;
            break;
        case ConditionFields.Location:
            patchModel.conditionLocation = value as string;
            break;
        case ConditionFields.Notes:
            patchModel.conditionNotes = value as string;
            break;
    }

    return patchModel;
};

const requestVolumeCalc = (data: ItemDetailsData) => {
    if (data.depth > 0 && data.width > 0 && data.height > 0) {
        const requestData: {[key:string]: any} = {
            depth: data.depth,
            width: data.width,
            height: data.height,
            lengthUnits: data.lengthUnits,
            volumeUnits: data.volumeUnits,
        };
        if (!!data.itemId) {
            requestData.itemId = data.itemId;
        }

        return apiClientInstance.fetchRequest<number>(
            `v1/items/calculate-volume`,
            'GET',
            requestData
        );
    } else {
        return new Promise<number>((resolve) => {
            resolve(data.volume);
        });
    }
};

const requestGrossVolumeCalc = (data: ItemDetailsData) => {
    if (data.packageDepth > 0 && data.packageWidth > 0 && data.packageHeight > 0) {
        const requestData: {[key:string]: any} = {
            packageDepth: data.packageDepth,
            packageWidth: data.packageWidth,
            packageHeight: data.packageHeight,
            lengthUnits: data.lengthUnits,
            volumeUnits: data.volumeUnits,
            category: data.category,
            handlingType: data.handlingType,
            grossVolume: data.grossVolume,
            volume: data.volume,
        };
        if (!!data.itemId) {
            requestData.itemId = data.itemId;
        }

        return apiClientInstance.fetchRequest<number>(
            `v1/items/calculate-gross-volume`,
            'GET',
            requestData
        );
    } else {
        return new Promise<number>((resolve) => {
            resolve(data.grossVolume);
        });
    }
};

export const outsideActionCreators = {
    openItemDetailsById: (id: number, context: string, contextId: number) : AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails) {
            dispatch({ type: 'ITEM_DETAILS_INIT', data: {...EmptyData, itemId: id} });
            dispatch(insideActionCreators.requestData(context, contextId));
            dispatch({ type: 'ITEM_DETAILS_OPEN' });
        }
    },
    openItemDetails: (itemData?: ItemDetailsData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails) {
            if (!!itemData) {
                dispatch({ type: 'ITEM_DETAILS_INIT', data: itemData });
            }

            dispatch({ type: 'ITEM_DETAILS_OPEN' });
        }
    },
};

export const insideActionCreators = {
    requestData: (context: string, contextId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails) {
            !!detailsAbortController && detailsAbortController.abort();
            detailsAbortController = new AbortController();
            apiClientInstance.fetchRequest<ItemDetailsData>(`v1/items/${appState.itemDetails.data.itemId}?context=${context}&contextId=${contextId}`, 'GET', undefined, undefined, detailsAbortController)
                .then((data) => {
                    data.lastScanDate = !!data.lastScanDate ? new Date(data.lastScanDate) : null;
                    data.collectionDate = !!data.collectionDate ? new Date(data.collectionDate) : null;
                    data.packingDate = !!data.packingDate ? new Date(data.packingDate) : null;
                    data.receivedDate = !!data.receivedDate ? new Date(data.receivedDate) : null;
                    data.releaseDate = !!data.releaseDate ? new Date(data.releaseDate) : null;
                    data.deliveryDate = !!data.deliveryDate ? new Date(data.deliveryDate) : null;
                    data.handlingStatusDate = !!data.handlingStatusDate ? new Date(data.handlingStatusDate) : null;
                    data.customsStatusDate = !!data.customsStatusDate ? new Date(data.customsStatusDate) : null;
                    data.photoShootDate = !!data.photoShootDate ? new Date(data.photoShootDate) : null;

                    dispatch({ type: 'ITEM_DETAILS_INIT', data: data });
                })
                .catch((err) => {
                    dispatch({ type: 'ITEM_DETAILS_REQUEST_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_REQUEST', context: context, contextId: contextId });
        }
    },
    updateServerField: (serverField: ItemFieldsType, value: any, selectedItem?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails) {
            const patchModel = buildPatch(serverField, value, appState.itemDetails.context, appState.itemDetails.contextId);

            apiClientInstance.fetchRequest<ItemUpdateResult>(
                `v1/items/${appState.itemDetails.data.itemId}`,
                'PATCH',
                patchModel
            )
                .then((data) => {
                    let infoObj = {...(appState.itemDetails?.data ?? {} as ItemDetailsData)};

                    switch (serverField) {
                        case ItemFields.UniqueId:
                            infoObj.uniqueId = data.uniqueId;
                            break;
                        case ItemFields.PartsQty:
                            infoObj.partsQty = data.partsQty;
                            break;
                        case ItemFields.PiecesQty:
                            infoObj.piecesQty = data.piecesQty;
                            break;
                        case ItemFields.Description:
                            infoObj.description = data.description;
                            break;

                        case ItemFields.PackingType:
                            infoObj.packageType = data.packageType;
                            infoObj.packageDepth = data.packageDepth;
                            infoObj.packageWidth = data.packageWidth;
                            infoObj.packageHeight = data.packageHeight;
                            infoObj.grossVolume = data.grossVolume;
                            break;
                        case ItemFields.PBO:
                            infoObj.pbo = data.pbo;
                            break;
                        case ItemFields.PackageDepth:
                            infoObj.packageDepth = data.packageDepth;
                            infoObj.grossVolume = data.grossVolume;
                            break;
                        case ItemFields.PackageWidth:
                            infoObj.packageWidth = data.packageWidth;
                            infoObj.grossVolume = data.grossVolume;
                            break;
                        case ItemFields.PackageHeight:
                            infoObj.packageHeight = data.packageHeight;
                            infoObj.grossVolume = data.grossVolume;
                            break;

                        case ItemFields.Artist:
                            infoObj.artist = data.artist;
                            break;
                        case ItemFields.Title:
                            infoObj.title = data.title;
                            break;
                        case ItemFields.Materials:
                            infoObj.materials = data.materials;
                            break;
                        case ItemFields.Circa:
                            infoObj.circa = data.circa;
                            break;
                        case ItemFields.CountryOfOrigin:
                            infoObj.countryOfOrigin = data.countryOfOrigin;
                            break;

                        case ItemFields.Depth:
                            infoObj.depth = data.depth;
                            infoObj.volume = data.volume;
                            break;
                        case ItemFields.Width:
                            infoObj.width = data.width;
                            infoObj.volume = data.volume;
                            break;
                        case ItemFields.Height:
                            infoObj.height = data.height;
                            infoObj.volume = data.volume;
                            break;
                        case ItemFields.Volume:
                            infoObj.volume = data.volume;
                            infoObj.grossVolume = data.grossVolume;
                            break;
                        case ItemFields.GrossVolume:
                            infoObj.grossVolume = data.grossVolume;
                            break;
                        case ItemFields.Weight:
                            infoObj.weight = data.weight;
                            break;
                        case ItemFields.GrossWeight:
                            infoObj.grossWeight = data.grossWeight;
                            break;

                        case ItemFields.Value:
                            infoObj.value = data.value;
                            infoObj.hammerPrice = data.hammerPrice;
                            break;
                        case ItemFields.Currency:
                            infoObj.valuationCurrency = data.valuationCurrency;
                            break;
                        case ItemFields.VAT:
                            infoObj.vat = data.vat;
                            break;
                        case ItemFields.AmountToPay:
                            infoObj.amountToPay = data.amountToPay;
                            break;

                        case ItemFields.Condition:
                            infoObj.condition = value as string;
                            break;
                        case ItemFields.VendorPart:
                            infoObj.vendorPart = value as string;
                            break;
                        case ItemFields.Sidemark:
                            infoObj.sidemark = value as string;
                            break;
                        case ItemFields.Lot:
                            infoObj.lot = value as string;
                            break;
                        case ItemFields.Tag:
                            infoObj.tag = value as string;
                            break;
                        case ItemFields.Category:
                            infoObj.category = value as string;
                            break;

                        case ItemFields.Location:
                            if (!!value) {
                                infoObj.locationId = value as number | null;
                                infoObj.locationRef = selectedItem?.uniqueId ?? '';

                                infoObj.lastScanDate = !!data.lastScanDate ? new Date(data.lastScanDate) : null;
                            } else {
                                infoObj.locationId = null;
                                infoObj.locationRef = '';
                            }
                            break;
                        case ItemFields.LastScanDate:
                            infoObj.lastScanDate = !!data.lastScanDate ? new Date(data.lastScanDate) : null;
                            break;
                        case ItemFields.TransportMode:
                            infoObj.transportMode = data.transportMode;
                            break;
                        case ItemFields.Room:
                            infoObj.room = data.room;
                            break;
                        case ItemFields.HandlingType:
                            infoObj.handlingType = data.handlingType;
                            break;

                        case ItemFields.LengthUnits:
                            infoObj.lengthUnits = data.lengthUnits;
                            break;
                        case ItemFields.VolumeUnits:
                            infoObj.volumeUnits = data.volumeUnits;
                            break;
                        case ItemFields.WeightUnits:
                            infoObj.weightUnits = data.weightUnits;
                            break;

                        case ItemFields.Bonded:
                            infoObj.bonded = data.bonded;
                            break;
                        case ItemFields.CITES:
                            infoObj.cites = data.cites;
                            break;
                        case ItemFields.TA:
                            infoObj.ta = data.ta;
                            break;
                        case ItemFields.InCrate:
                            infoObj.toCrate = data.toCrate;
                            break;
                        case ItemFields.ExportLicenseRequired:
                            infoObj.exportLicenseRequired = data.exportLicenseRequired;
                            break;

                        case ItemFields.CollectionDate:
                            infoObj.collectionDate = !!data.collectionDate ? new Date(data.collectionDate) : null;
                            break;
                        case ItemFields.PackingDate:
                            infoObj.packingDate = !!data.packingDate ? new Date(data.packingDate) : null;
                            break;
                        case ItemFields.ReceivedDate:
                            infoObj.receivedDate = !!data.receivedDate ? new Date(data.receivedDate) : null;
                            break;
                        case ItemFields.ReleaseDate:
                            infoObj.releaseDate = !!data.releaseDate ? new Date(data.releaseDate) : null;
                            break;
                        case ItemFields.DeliveryDate:
                            infoObj.deliveryDate = !!data.deliveryDate ? new Date(data.deliveryDate) : null;
                            break;
                        case ItemFields.CollectionDateDone:
                            infoObj.isCollected = data.isCollected;
                            if (data.isCollected === false)
                                infoObj.collectionDate = null;
                            break;
                        case ItemFields.PackingDateDone:
                            infoObj.isPacked = data.isPacked;
                            if (data.isPacked === false)
                                infoObj.packingDate = null;
                            break;
                        case ItemFields.ReceivedDateDone:
                            infoObj.isReceived = data.isReceived;
                            if (data.isReceived === false)
                                infoObj.receivedDate = null;
                            break;
                        case ItemFields.ReleaseDateDone:
                            infoObj.isReleased = data.isReleased;
                            if (data.isReleased === false)
                                infoObj.releaseDate = null;
                            break;
                        case ItemFields.DeliveryDateDone:
                            infoObj.isDelivered = data.isDelivered;
                            if (data.isDelivered === false)
                                infoObj.deliveryDate = null;
                            break;

                        case ItemFields.Comments:
                            infoObj.comments = data.comments;
                            break;
                        case ItemFields.Registar:
                            infoObj.registarNotes = data.registarNotes;
                            break;

                        case ItemFields.HammerPrice:
                            infoObj.hammerPrice = data.hammerPrice;
                            break;
                        case ItemFields.BuyerPremium:
                            infoObj.buyerPremium = data.buyerPremium;
                            break;
                        case ItemFields.Owner:
                            let owner = selectedItem as PartyListItem;
                            infoObj.ownerId = value as number;
                            infoObj.ownerRef = owner?.legalName ?? '';
                            infoObj.ownerType = owner?.partyType ?? '';
                            break;
                        case ItemFields.Buyer:
                            let buyer = selectedItem as PartyListItem;
                            infoObj.buyerId = value as number;
                            infoObj.buyerRef = buyer?.legalName ?? '';
                            infoObj.buyerType = buyer?.partyType ?? '';
                            break;
                        case ItemFields.Seller:
                            let seller = selectedItem as PartyListItem;
                            infoObj.sellerId = value as number;
                            infoObj.sellerRef = seller?.legalName ?? '';
                            infoObj.sellerType = seller?.partyType ?? '';
                            break;

                        case ItemFields.InCare:
                            infoObj.inCare = data.inCare;
                            break;
                        case ItemFields.Assemble:
                            infoObj.assemble = data.assemble;
                            break;
                        case ItemFields.CustomsStatus:
                            infoObj.customsStatus = data.customsStatus;
                            break;
                        case ItemFields.CustomsStatusDate:
                            infoObj.customsStatusDate = !!data.customsStatusDate ? new Date(data.customsStatusDate) : null;
                            break;
                        case ItemFields.CustomsEntryNumber:
                            infoObj.customsEntryNumber = data.customsEntryNumber;
                            break;
                        case ItemFields.Disposition:
                            infoObj.disposition = data.disposition;
                            break;

                        case ItemFields.ShippingCost:
                            infoObj.shippingCost = data.shippingCost;
                            break;
                        case ItemFields.InsurancePremium:
                            infoObj.insurancePremium = data.insurancePremium;
                            break;
                        case ItemFields.HSCode:
                            infoObj.hsCode = data.hscode;
                            break;
                        case ItemFields.InsuredByClient:
                            infoObj.insuredByClient = data.insuredByClient;
                            break;
                        case ItemFields.ShippingTerms:
                            infoObj.shippingTerms = data.shippingTerms;
                            break;

                        case ItemFields.PhotoShootDate:
                            infoObj.photoShootDate = !!data.photoShootDate ? new Date(data.photoShootDate) : null;
                            break;
                        case ItemFields.ShotBy:
                            infoObj.shotBy = data.shotBy;
                            break;
                        case ItemFields.IsCatalogued:
                            infoObj.isCatalogued = data.isCatalogued;
                            break;

                        case ItemFields.VatOnHammerPrice:
                            infoObj.vatonHammerPrice = data.vatonHammerPrice;
                            break;
                        case ItemFields.VatRateOnHammer:
                            infoObj.vatrateOnHammerPrice = data.vatrateOnHammerPrice;
                            break;
                        case ItemFields.VatOnBuyerPremium:
                            infoObj.vatonBuyerPremium = data.vatonBuyerPremium;
                            break;
                        case ItemFields.VatRateOnBuyerPremium:
                            infoObj.vatrateOnBuyerPremium = data.vatrateOnBuyerPremium;
                            break;
                        case ItemFields.ArtistRoyalty:
                            infoObj.artistRoyalty = data.artistRoyalty;
                            break;

                        case ItemFields.Movement:
                            infoObj.movement = data.movement;
                            break;
                        case ItemFields.Accessories:
                            infoObj.accessories = data.accessories;
                            break;

                        case ItemFields.TransferredTo:
                            infoObj.transferredTo = null;
                            infoObj.transferredToRef = "";
                            break;
                    }

                    dispatch({ type: 'ITEM_DETAILS_UPDATE_DATA', data: infoObj });
                })
                .catch((err) => {
                    dispatch({ type: 'ITEM_DETAILS_UPDATE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_UPDATE_DATA_REQUEST', field: serverField });
        }
    },
    updateLocalField: (serverField: ItemFieldsType, value: any, selectedItem?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails) {
            new Promise<ItemDetailsData>(async (resolve) => {
                let infoObj = {...(appState.itemDetails?.data ?? {} as ItemDetailsData)};

                switch (serverField) {
                    case ItemFields.UniqueId:
                        infoObj.uniqueId = value as string;
                        break;
                    case ItemFields.PartsQty:
                        infoObj.partsQty = value as number;
                        break;
                    case ItemFields.PiecesQty:
                        infoObj.piecesQty = value as number;
                        break;
                    case ItemFields.Description:
                        infoObj.description = value as string;
                        break;

                    case ItemFields.PackingType:
                        infoObj.packageType = value as string;
                        const packingType = selectedItem as PackingTypeItem;
                        infoObj.packageWidth = packingType.width;
                        infoObj.packageDepth = packingType.depth;
                        infoObj.packageHeight = packingType.height;
                        break;
                    case ItemFields.PBO:
                        infoObj.pbo = value as boolean;
                        break;
                    case ItemFields.PackageDepth:
                        infoObj.packageDepth = value as number;
                        await requestGrossVolumeCalc(infoObj)
                            .then((value) => {
                                infoObj.grossVolume = value;
                            })
                            .catch(err => {
                                dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                            });
                        break;
                    case ItemFields.PackageWidth:
                        infoObj.packageWidth = value as number;
                        await requestGrossVolumeCalc(infoObj)
                            .then((value) => {
                                infoObj.grossVolume = value;
                            })
                            .catch(err => {
                                dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                            });
                        break;
                    case ItemFields.PackageHeight:
                        infoObj.packageHeight = value as number;
                        await requestGrossVolumeCalc(infoObj)
                            .then((value) => {
                                infoObj.grossVolume = value;
                            })
                            .catch(err => {
                                dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                            });
                        break;

                    case ItemFields.Artist:
                        infoObj.artist = value as string;
                        break;
                    case ItemFields.Title:
                        infoObj.title = value as string;
                        break;
                    case ItemFields.Materials:
                        infoObj.materials = value as string;
                        break;
                    case ItemFields.Circa:
                        infoObj.circa = value as string;
                        break;
                    case ItemFields.CountryOfOrigin:
                        infoObj.countryOfOrigin = value as string;
                        break;

                    case ItemFields.Depth:
                        infoObj.depth = value as number;
                        await requestVolumeCalc(infoObj)
                            .then((value) => {
                                infoObj.volume = value;
                            })
                            .catch(err => {
                                dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                            });
                        break;
                    case ItemFields.Width:
                        infoObj.width = value as number;
                        await requestVolumeCalc(infoObj)
                            .then((value) => {
                                infoObj.volume = value;
                            })
                            .catch(err => {
                                dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                            });
                        break;
                    case ItemFields.Height:
                        infoObj.height = value as number;
                        await requestVolumeCalc(infoObj)
                            .then((value) => {
                                infoObj.volume = value;
                            })
                            .catch(err => {
                                dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                            });
                        break;
                    case ItemFields.Volume:
                        infoObj.volume = value as number;
                        break;
                    case ItemFields.GrossVolume:
                        infoObj.grossVolume = value as number;
                        break;
                    case ItemFields.Weight:
                        infoObj.weight = value as number;
                        break;
                    case ItemFields.GrossWeight:
                        infoObj.grossWeight = value as number;
                        break;

                    case ItemFields.Value:
                        infoObj.value = value as number;
                        infoObj.hammerPrice = value as number;
                        break;
                    case ItemFields.Currency:
                        infoObj.valuationCurrency = value as string;
                        break;
                    case ItemFields.VAT:
                        infoObj.vat = value as number;
                        break;
                    case ItemFields.AmountToPay:
                        infoObj.amountToPay = value as number;
                        break;

                    case ItemFields.Condition:
                        infoObj.condition = value as string;
                        break;
                    case ItemFields.VendorPart:
                        infoObj.vendorPart = value as string;
                        break;
                    case ItemFields.Sidemark:
                        infoObj.sidemark = value as string;
                        break;
                    case ItemFields.Lot:
                        infoObj.lot = value as string;
                        break;
                    case ItemFields.Tag:
                        infoObj.tag = value as string;
                        break;
                    case ItemFields.Category:
                        infoObj.category = value as string;
                        break;

                    case ItemFields.Location:
                        if (!!value) {
                            infoObj.locationId = value as number;
                            infoObj.locationRef = selectedItem?.uniqueId ?? '';

                            const now = new Date();
                            infoObj.lastScanDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                        } else {
                            infoObj.locationId = null;
                            infoObj.locationRef = '';
                        }
                        break;
                    case ItemFields.LastScanDate:
                        infoObj.lastScanDate = value as Date;
                        break;
                    case ItemFields.TransportMode:
                        infoObj.transportMode = value as string;
                        break;
                    case ItemFields.Room:
                        infoObj.room = value as string;
                        break;
                    case ItemFields.HandlingType:
                        infoObj.handlingType = value as string;
                        break;

                    case ItemFields.LengthUnits:
                        infoObj.lengthUnits = value as string;
                        break;
                    case ItemFields.VolumeUnits:
                        infoObj.volumeUnits = value as string;
                        break;
                    case ItemFields.WeightUnits:
                        infoObj.weightUnits = value as string;
                        break;

                    case ItemFields.Bonded:
                        infoObj.bonded = value as boolean;
                        break;
                    case ItemFields.CITES:
                        infoObj.cites = value as boolean;
                        break;
                    case ItemFields.TA:
                        infoObj.ta = value as boolean;
                        break;
                    case ItemFields.InCrate:
                        infoObj.toCrate = value as boolean;
                        break;
                    case ItemFields.ExportLicenseRequired:
                        infoObj.exportLicenseRequired = value as boolean;
                        break;

                    case ItemFields.CollectionDate:
                        infoObj.collectionDate = value as Date | null;
                        break;
                    case ItemFields.PackingDate:
                        infoObj.packingDate = value as Date | null;
                        break;
                    case ItemFields.ReceivedDate:
                        infoObj.receivedDate = value as Date | null;
                        break;
                    case ItemFields.ReleaseDate:
                        infoObj.releaseDate = value as Date | null;
                        break;
                    case ItemFields.DeliveryDate:
                        infoObj.deliveryDate = value as Date | null;
                        break;
                    case ItemFields.CollectionDateDone:
                        infoObj.isCollected = value as boolean;
                        if (infoObj.isCollected === false)
                            infoObj.collectionDate = null;
                        break;
                    case ItemFields.PackingDateDone:
                        infoObj.isPacked = value as boolean;
                        if (infoObj.isPacked === false)
                            infoObj.packingDate = null;
                        break;
                    case ItemFields.ReceivedDateDone:
                        infoObj.isReceived = value as boolean;
                        if (infoObj.isReceived === false)
                            infoObj.receivedDate = null;
                        break;
                    case ItemFields.ReleaseDateDone:
                        infoObj.isReleased = value as boolean;
                        if (infoObj.isReleased === false)
                            infoObj.releaseDate = null;
                        break;
                    case ItemFields.DeliveryDateDone:
                        infoObj.isDelivered = value as boolean;
                        if (infoObj.isDelivered === false)
                            infoObj.deliveryDate = null;
                        break;

                    case ItemFields.Comments:
                        infoObj.comments = value as string;
                        break;
                    case ItemFields.Registar:
                        infoObj.registarNotes = value as string;
                        break;

                    case ItemFields.HammerPrice:
                        infoObj.hammerPrice = value as number;
                        break;
                    case ItemFields.BuyerPremium:
                        infoObj.buyerPremium = value as number;
                        break;
                    case ItemFields.Owner:
                        let owner = selectedItem as PartyListItem;
                        infoObj.ownerId = value as number;
                        infoObj.ownerRef = owner?.legalName ?? '';
                        break;
                    case ItemFields.Buyer:
                        let buyer = selectedItem as PartyListItem;
                        infoObj.buyerId = value as number;
                        infoObj.buyerRef = buyer?.legalName ?? '';
                        break;
                    case ItemFields.Seller:
                        let seller = selectedItem as PartyListItem;
                        infoObj.sellerId = value as number;
                        infoObj.sellerRef = seller?.legalName ?? '';
                        break;

                    case ItemFields.InCare:
                        infoObj.inCare = value as boolean;
                        break;
                    case ItemFields.Assemble:
                        infoObj.assemble = value as boolean;
                        break;
                    case ItemFields.CustomsStatus:
                        infoObj.customsStatus = value as string;
                        break;
                    case ItemFields.CustomsStatusDate:
                        infoObj.customsStatusDate = value as Date;
                        break;
                    case ItemFields.CustomsEntryNumber:
                        infoObj.customsEntryNumber = value as string;
                        break;
                    case ItemFields.Disposition:
                        infoObj.disposition = value as string;
                        break;

                    case ItemFields.ShippingCost:
                        infoObj.shippingCost = value as number;
                        break;
                    case ItemFields.InsurancePremium:
                        infoObj.insurancePremium = value as number;
                        break;
                    case ItemFields.HSCode:
                        infoObj.hsCode = value as string;
                        break;
                    case ItemFields.InsuredByClient:
                        infoObj.insuredByClient = value as boolean;
                        break;
                    case ItemFields.ShippingTerms:
                        infoObj.shippingTerms = value as string;
                        break;

                    case ItemFields.PhotoShootDate:
                        infoObj.photoShootDate = value as Date | null;
                        break;
                    case ItemFields.ShotBy:
                        infoObj.shotBy = value as string;
                        break;
                    case ItemFields.IsCatalogued:
                        infoObj.isCatalogued = value as boolean;
                        break;

                    case ItemFields.VatOnHammerPrice:
                        infoObj.vatonHammerPrice = value as number;
                        break;
                    case ItemFields.VatRateOnHammer:
                        infoObj.vatrateOnHammerPrice = value as number;
                        break;
                    case ItemFields.VatOnBuyerPremium:
                        infoObj.vatonBuyerPremium = value as number;
                        break;
                    case ItemFields.VatRateOnBuyerPremium:
                        infoObj.vatrateOnBuyerPremium = value as number;
                        break;
                    case ItemFields.ArtistRoyalty:
                        infoObj.artistRoyalty = value as number;
                        break;

                    case ItemFields.Movement:
                        infoObj.movement = value as string;
                        break;
                    case ItemFields.Accessories:
                        infoObj.accessories = value as string;
                        break;
                }

                resolve(infoObj as ItemDetailsData);
            })
            .then((data) => {
                dispatch({ type: 'ITEM_DETAILS_UPDATE_DATA', data: data });
            });
        }
    },
    closeItemDetails: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails) {
            dispatch({ type: 'ITEM_DETAILS_CLOSE' });
        }
    },
    splitItem: (keepOriginalValues: boolean, items: Array<SplitItem>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails) {
            const model = {
                items: items,
                keepOriginalValues: keepOriginalValues,
            };

            apiClientInstance.fetchRequest(`/v1/items/${appState.itemDetails.data.itemId}/split`, 'POST', model)
                .then(() => {
                    dispatch({
                        type: 'ITEM_DETAILS_SPLIT_SUCCESS',
                        partsQty: items.reduce((a, b) => { return a + b.partsQty; }, 0),
                        piecesQty: items.reduce((a, b) => { return a + b.piecesQty; }, 0),
                    });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_SPLIT_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_SPLIT_REQUEST' });
        }
    },
    addPackages: (items: Array<AddPackageItem>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails) {
            const model = {
                items: items,
            };

            apiClientInstance.fetchRequest(`/v1/items/${appState.itemDetails.data.itemId}/add-packages`, 'POST', model)
                .then(() => {
                    dispatch({
                        type: 'ITEM_DETAILS_ADDPACKAGES_SUCCESS',
                        partsQty: items.reduce((a, b) => { return a + b.partsQty; }, 0),
                        piecesQty: items.reduce((a, b) => { return a + b.piecesQty; }, 0),
                    });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_ADDPACKAGES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_ADDPACKAGES_REQUEST' });
        }
    },
    convertVolume: () : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails) {
            const model = {
                itemId: appState.itemDetails.data.itemId > 0 ? appState.itemDetails.data.itemId : null,
                volume: appState.itemDetails.data.volume,
                conversionType: appState.itemDetails.data.volumeUnits === Units.Cuft
                    ? UnitConversionType.Cuft2Cbm
                    : UnitConversionType.Cbm2Cuft,
            };

            apiClientInstance.fetchRequest<number>(`/v1/items/convert-volume`, 'POST', model)
                .then((data) => {
                    dispatch({
                        type: 'ITEM_DETAILS_CONVERT_VOLUME_RECEIVE',
                        volume: data,
                    });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_CONVERT_VOLUME_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_CONVERT_VOLUME_REQUEST' });
        }
    },
    convertGrossVolume: () : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails) {
            const model = {
                itemId: appState.itemDetails.data.itemId > 0 ? appState.itemDetails.data.itemId : null,
                volume: appState.itemDetails.data.grossVolume,
                conversionType: appState.itemDetails.data.volumeUnits === Units.Cuft
                    ? UnitConversionType.Cuft2Cbm
                    : UnitConversionType.Cbm2Cuft,
            };

            apiClientInstance.fetchRequest<number>(`/v1/items/convert-gross-volume`, 'POST', model)
                .then((data) => {
                    dispatch({
                        type: 'ITEM_DETAILS_CONVERT_GROSS_VOLUME_RECEIVE',
                        volume: data,
                    });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_CONVERT_GROSS_VOLUME_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_CONVERT_GROSS_VOLUME_REQUEST' });
        }
    },
    updatePartsQty: (value: number, childrenValues: Array<{ itemId: number, partsQty: number }>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails) {
            const model = {
                partsQty: value,
                childrenValues: childrenValues,
            };

            apiClientInstance.fetchRequest(`/v1/items/${appState.itemDetails.data.itemId}/update-parts-qty`, 'POST', model)
                .then(() => {
                    dispatch({
                        type: 'ITEM_DETAILS_UPDATE_PARTSQTY_RECEIVE',
                        partsQty: value,
                        childrenValues: childrenValues,
                    });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_UPDATE_PARTSQTY_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_UPDATE_PARTSQTY_REQUEST' });
        }
    },
    updatePiecesQty: (value: number, childrenValues: Array<{ itemId: number, piecesQty: number }>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails) {
            const model = {
                piecesQty: value,
                childrenValues: childrenValues,
            };

            apiClientInstance.fetchRequest(`/v1/items/${appState.itemDetails.data.itemId}/update-pieces-qty`, 'POST', model)
                .then(() => {
                    dispatch({
                        type: 'ITEM_DETAILS_UPDATE_PIECESQTY_RECEIVE',
                        piecesQty: value,
                        childrenValues: childrenValues,
                    });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_UPDATE_PIECESQTY_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_UPDATE_PIECESQTY_REQUEST' });
        }
    },

    requestChildItems: (skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails?.childItems) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<ItemChild>>(`/v1/items/${appState.itemDetails.data.itemId}/children`, 'GET', {}, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ItemChildren}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ItemChildren}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'ITEM_DETAILS_CHILDREN_RECEIVE', items: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_CHILDREN_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_CHILDREN_REQUEST', skip: skip, });
        }
    },
    startDeletePackage: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails && appState.itemDetails.childItems) {
            dispatch({ type: 'ITEM_DETAILS_CHILDREN_DELETE_START' });
        }
    },
    submitDeletePackage: (itemIds: Array<number>):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails && appState.itemDetails.childItems) {
            apiClientInstance.fetchRequest(
                `/v1/items/`,
                'DELETE',
                { itemIds: itemIds }
            )
                .then(() => {
                    dispatch({ type: 'ITEM_DETAILS_CHILDREN_DELETE_SUCCESS', ids: itemIds });
                })
                .catch((err) => {
                    dispatch({ type: 'ITEM_DETAILS_CHILDREN_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_CHILDREN_DELETE_SEND' });
        }
    },
    cancelDeletePackage: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails && appState.itemDetails.childItems) {
            dispatch({ type: 'ITEM_DETAILS_CHILDREN_DELETE_CANCEL' });
        }
    },

    requestTransactionHistory: (skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails?.childItems) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<ItemHistoryItem>>(`/v1/items/${appState.itemDetails.data.itemId}/transaction-history`, 'GET', {}, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ItemTransactionHistory}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ItemTransactionHistory}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((hst) => hst.scheduledDate = !!hst.scheduledDate ? new Date(hst.scheduledDate) : null);
                    data.data.forEach((hst) => hst.completedDate = !!hst.completedDate ? new Date(hst.completedDate) : null);

                    dispatch({ type: 'ITEM_DETAILS_TRANSACTION_HISTORY_RECEIVE', histories: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_TRANSACTION_HISTORY_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_TRANSACTION_HISTORY_REQUEST', skip: skip, });
        }
    },
    requestLocationHistory: (skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails?.childItems) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<ItemLocationHistoryItem>>(`/v1/items/${appState.itemDetails.data.itemId}/location-history`, 'GET', {}, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ItemLocationHistory}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ItemLocationHistory}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((hst) => hst.date = !!hst.date ? new Date(hst.date) : null);

                    dispatch({ type: 'ITEM_DETAILS_LOCATION_HISTORY_RECEIVE', histories: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_LOCATION_HISTORY_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_LOCATION_HISTORY_REQUEST', skip: skip, });
        }
    },
    requestPackageHistory: (skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails?.childItems) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<ItemPackageHistoryItem>>(`/v1/items/${appState.itemDetails.data.itemId}/package-history`, 'GET', {}, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ItemPackageHistory}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ItemPackageHistory}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((hst) => hst.date = !!hst.date ? new Date(hst.date) : null);

                    dispatch({ type: 'ITEM_DETAILS_PACKAGE_HISTORY_RECEIVE', histories: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_PACKAGE_HISTORY_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_PACKAGE_HISTORY_REQUEST', skip: skip, });
        }
    },
    requestActionLogs: (skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && !appState.itemDetails?.actionLogs.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<ActionLogItem>>(`/v1/items/${appState.itemDetails.data.itemId}/action-log`, 'GET', {}, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ItemActionLog}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ItemActionLog}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((log) => log.actionDate = !!log.actionDate ? new Date(log.actionDate) : null);

                    dispatch({ type: 'ITEM_DETAILS_ACTION_LOG_RECEIVE', logs: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_ACTION_LOG_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_ACTION_LOG_REQUEST', skip: skip, });
        }
    },

    requestConditions: (): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && !appState.itemDetails?.conditions.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<ItemCondition>>(`/v1/items/${appState.itemDetails.data.itemId}/conditions`, 'GET', {})
                .then(data => {
                    data.data.forEach((log) => log.createdDate = !!log.createdDate ? new Date(log.createdDate) : null);

                    dispatch({ type: 'ITEM_DETAILS_CONDITIONS_RECEIVE', conditions: data.data });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_CONDITIONS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_CONDITIONS_REQUEST' });
        }
    },
    updateConditionField: (id: number, serverField: ConditionFieldsType, value: any, selectedItem?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails.conditions) {
            const patchModel = buildConditionPatch(serverField, value);

            apiClientInstance.fetchRequest(
                `v1/item-conditions/${id}`,
                'PATCH',
                patchModel
            )
                .then(() => {
                    let condition = {...(appState.itemDetails?.conditions.conditions.find(x => x.itemConditionImageId === id) ?? {} as ItemCondition)};

                    switch (serverField) {
                        case ConditionFields.Position:
                            condition.conditionPosition = value as number;
                            break;
                        case ConditionFields.ConditionType:
                            condition.conditionType = value as string;
                            break;
                        case ConditionFields.Location:
                            condition.conditionLocation = value as string;
                            break;
                        case ConditionFields.Notes:
                            condition.conditionNotes = value as string;
                            break;
                    }

                    dispatch({ type: 'ITEM_DETAILS_CONDITIONS_UPDATE_DATA', data: condition, });
                })
                .catch((err) => {
                    dispatch({ type: 'ITEM_DETAILS_CONDITIONS_UPDATE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_CONDITIONS_UPDATE_DATA_REQUEST', field: serverField });
        }
    },
    createCondition: (data: ItemCondition): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && !appState.itemDetails?.conditions.isLoading) {
            apiClientInstance.fetchRequest<ItemCondition>(`/v1/items/${appState.itemDetails.data.itemId}/conditions`, 'POST', data)
                .then(result => {
                    !!result.createdDate && (result.createdDate = new Date(result.createdDate));

                    dispatch({ type: 'ITEM_DETAILS_CONDITIONS_CREATE_SUCCESS', entity: result });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_CONDITIONS_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_CONDITIONS_CREATE_REQUEST' });
        }
    },
    deleteCondition: (id: number): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && !appState.itemDetails?.conditions.isLoading) {
            apiClientInstance.fetchRequest(`/v1/item-conditions/${id}`, 'DELETE')
                .then(() => {
                    dispatch({ type: 'ITEM_DETAILS_CONDITIONS_DELETE_SUCCESS', deletedId: id });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_CONDITIONS_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_CONDITIONS_DELETE_REQUEST' });
        }
    },

    requestDocuments: (skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && !appState.itemDetails?.customsDocuments.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<CustomsDocumentHistoryItem>>(`/v1/items/${appState.itemDetails.data.itemId}/customs-documents`, 'GET', {}, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ItemCustomsDocuments}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ItemCustomsDocuments}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((log) => log.createdOn = !!log.createdOn ? new Date(log.createdOn) : null);
                    data.data.forEach((log) => log.expiryDate = !!log.expiryDate ? new Date(log.expiryDate) : null);
                    data.data.forEach((log) => log.docReplacedDate = !!log.docReplacedDate ? new Date(log.docReplacedDate) : null);

                    dispatch({ type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_RECEIVE', documents: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_REQUEST', skip: skip, });
        }
    },
    updateDocumentField: (id: number, serverField: CustomsDocumentsFieldsType, value: any, selectedItem?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemDetails.customsDocuments) {
            const patchModel = buildDocumentPatch(serverField, value);

            apiClientInstance.fetchRequest(
                `v1/customs-documents/${id}`,
                'PATCH',
                patchModel
            )
                .then((data) => {
                    let doc = {...(appState.itemDetails?.customsDocuments.documents.find(x => x.customsDocumentHistoryId === id) ?? {} as CustomsDocumentHistoryItem)};

                    switch (serverField) {
                        case CustomsDocumentsFields.JobFile:
                            doc.fileName = value as string;
                            break;
                        case CustomsDocumentsFields.ItemNr:
                            doc.itemNr = value as string;
                            break;
                        case CustomsDocumentsFields.DocNr:
                            doc.docNr = value as string;
                            break;
                        case CustomsDocumentsFields.DocumentType:
                            doc.docType = value as string;
                            break;
                        case CustomsDocumentsFields.CreationDate:
                            doc.createdOn = patchModel.createdOn as Date;
                            break;
                        case CustomsDocumentsFields.ExpiryDate:
                            doc.expiryDate = patchModel.expiryDate as Date;
                            break;
                        case CustomsDocumentsFields.PrevStatus:
                            doc.prevStatus = value as string;
                            break;
                        case CustomsDocumentsFields.CurrentStatus:
                            doc.currentStatus = value as string;
                            break;
                        case CustomsDocumentsFields.TransitDocNr:
                            doc.transferDocNr = value as string;
                            break;
                        case CustomsDocumentsFields.DocIssuedBy:
                            doc.issuedBy = value as string;
                            break;
                        case CustomsDocumentsFields.AFC:
                            doc.afc = value as boolean;
                            break;
                        case CustomsDocumentsFields.CustomsOfficeLocationDeparture:
                            doc.customsOfficeDeparture = value as string;
                            break;
                        case CustomsDocumentsFields.CountryOfDeparture:
                            doc.countryOfOrigin = value as string;
                            break;
                        case CustomsDocumentsFields.CustomsOfficeLocationDestination:
                            doc.customsOfficeArrival = value as string;
                            break;
                        case CustomsDocumentsFields.CountryOfDestination:
                            doc.countryOfDestination = value as string;
                            break;
                        case CustomsDocumentsFields.ReplacedDocumentNr:
                            doc.docReplaced = value as string;
                            break;
                        case CustomsDocumentsFields.ReplacedDocumentDate:
                            doc.docReplacedDate = patchModel.docReplacedDate as Date;
                            break;
                        case CustomsDocumentsFields.TempExtensionNr:
                            doc.tempExtensionNr = value as number;
                            break;
                        case CustomsDocumentsFields.TempDocSentTo:
                            doc.tempDocSentTo = value as string;
                            break;
                        case CustomsDocumentsFields.ReceivedFrom:
                            doc.receivedFromId = value as number;
                            doc.receivedFromName = (selectedItem as PartyListItem).fullName;
                            break;
                        case CustomsDocumentsFields.ReleasedTo:
                            doc.releasedToId = value as number;
                            doc.releasedToName = (selectedItem as PartyListItem).fullName;
                            break;
                    }

                    dispatch({ type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_UPDATE_DATA', data: doc, });
                })
                .catch((err) => {
                    dispatch({ type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_UPDATE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_UPDATE_DATA_REQUEST', field: serverField });
        }
    },
    createDocument: (data: CustomsDocumentHistoryItem): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && !appState.itemDetails?.customsDocuments.isLoading) {
            apiClientInstance.fetchRequest<CustomsDocumentHistoryItem>(`/v1/items/${appState.itemDetails.data.itemId}/customs-documents`, 'POST', data)
                .then(result => {
                    !!result.createdOn && (result.createdOn = new Date(result.createdOn));
                    !!result.expiryDate && (result.expiryDate = new Date(result.expiryDate));
                    !!result.docReplacedDate && (result.docReplacedDate = new Date(result.docReplacedDate));

                    dispatch({ type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_CREATE_SUCCESS', entity: result });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_CREATE_REQUEST' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ItemDetailsState = {
    data: EmptyData,
    context: '',
    contextId: 0,
    status: ServerOperationStatus.NONE,
    updateStatus: ServerOperationStatus.NONE,
    piecesQtyUpdateStatus: ServerOperationStatus.NONE,
    partsQtyUpdateStatus: ServerOperationStatus.NONE,
    splitStatus: ServerOperationStatus.NONE,
    addPackagesStatus: ServerOperationStatus.NONE,
    childItems: {
        items: [],
        count: 0,
        skip: 0,
        isLoading: false,
        deleteStatus: ServerOperationStatus.NONE,
    },
    transactionHistory: {
        historyItems: [],
        count: 0,
        skip: 0,
        isLoading: false,
    },
    locationHistory: {
        locations: [],
        count: 0,
        skip: 0,
        isLoading: false,
    },
    packageHistory: {
        packages: [],
        count: 0,
        skip: 0,
        isLoading: false,
    },
    actionLogs: {
        logs: [],
        count: 0,
        skip: 0,
        isLoading: false,
    },
    conditions: {
        conditions: [],
        isLoading: false,
        createStatus: ServerOperationStatus.NONE,
        updateStatus: ServerOperationStatus.NONE,
        deleteStatus: ServerOperationStatus.NONE,
    },
    customsDocuments: {
        documents: [],
        count: 0,
        skip: 0,
        isLoading: false,
        createStatus: ServerOperationStatus.NONE,
        updateStatus: ServerOperationStatus.NONE,
    },
};

export const reducer: Reducer<ItemDetailsState> = (state: ItemDetailsState | undefined, incomingAction: Action): ItemDetailsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ITEM_DETAILS_OPEN':
            return {
                ...state,
                status: ServerOperationStatus.READY,
            };
        case 'ITEM_DETAILS_REQUEST':
            return {
                ...state,
                status: ServerOperationStatus.INPROGRESS,
                context: action.context,
                contextId: action.contextId,
            };
        case 'ITEM_DETAILS_REQUEST_ERROR':
            return {
                ...state,
                status: ServerOperationStatus.ERROR,
            };
        case 'ITEM_DETAILS_INIT':
            return {
                ...state,
                data: action.data,
                status: ServerOperationStatus.READY,
            };
        case 'ITEM_DETAILS_UPDATE_DATA':
            return {
                ...state,
                data: action.data,
                updateStatus: ServerOperationStatus.SUCCESS,
                updatedField: undefined,
            };
        case 'ITEM_DETAILS_UPDATE_DATA_REQUEST':
            return {
                ...state,
                updateStatus: ServerOperationStatus.INPROGRESS,
                updatedField: action.field,
            };
        case 'ITEM_DETAILS_UPDATE_DATA_ERROR':
            return {
                ...state,
                updateStatus: ServerOperationStatus.ERROR,
                updatedField: undefined,
            };
        case 'ITEM_DETAILS_CONVERT_VOLUME_REQUEST':
            return {
                ...state,
                updatedField: ItemFields.Volume,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'ITEM_DETAILS_CONVERT_VOLUME_RECEIVE':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.SUCCESS,
                data: {
                    ...state.data,
                    volume: action.volume,
                },
            };
        case 'ITEM_DETAILS_CONVERT_VOLUME_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };
        case 'ITEM_DETAILS_CONVERT_GROSS_VOLUME_REQUEST':
            return {
                ...state,
                updatedField: ItemFields.GrossVolume,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'ITEM_DETAILS_CONVERT_GROSS_VOLUME_RECEIVE':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.SUCCESS,
                data: {
                    ...state.data,
                    grossVolume: action.volume,
                },
            };
        case 'ITEM_DETAILS_CONVERT_GROSS_VOLUME_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };

        case 'ITEM_DETAILS_UPDATE_PARTSQTY_REQUEST':
            return {
                ...state,
                partsQtyUpdateStatus: ServerOperationStatus.INPROGRESS
            };
        case 'ITEM_DETAILS_UPDATE_PARTSQTY_RECEIVE':
            {
                const newItems = state.childItems.items.map(x => {
                    return {
                        ...x,
                        partsQty: action.childrenValues.find(cv => cv.itemId === x.itemId)!.partsQty,
                    };
                });

                return {
                    ...state,
                    partsQtyUpdateStatus: ServerOperationStatus.SUCCESS,
                    data: {
                        ...state.data,
                        partsQty: action.partsQty,
                    },
                    childItems: {
                        ...state.childItems,
                        items: newItems,
                    },
                };
            }
        case 'ITEM_DETAILS_UPDATE_PARTSQTY_ERROR':
            return {
                ...state,
                partsQtyUpdateStatus: ServerOperationStatus.ERROR,
            };

        case 'ITEM_DETAILS_UPDATE_PIECESQTY_REQUEST':
            return {
                ...state,
                piecesQtyUpdateStatus: ServerOperationStatus.INPROGRESS
            };
        case 'ITEM_DETAILS_UPDATE_PIECESQTY_RECEIVE':
            {
                const newItems = state.childItems.items.map(x => {
                    return {
                        ...x,
                        piecesQty: action.childrenValues.find(cv => cv.itemId === x.itemId)!.piecesQty,
                    };
                });

                return {
                    ...state,
                    piecesQtyUpdateStatus: ServerOperationStatus.SUCCESS,
                    data: {
                        ...state.data,
                        piecesQty: action.piecesQty,
                    },
                    childItems: {
                        ...state.childItems,
                        items: newItems,
                    },
                };
            }
        case 'ITEM_DETAILS_UPDATE_PIECESQTY_ERROR':
            return {
                ...state,
                piecesQtyUpdateStatus: ServerOperationStatus.ERROR,
            };

        case 'ITEM_DETAILS_CLOSE':
            return {
                ...unloadedState,
            };

        case 'ITEM_DETAILS_CHILDREN_REQUEST':
            return {
                ...state,
                childItems: {
                    ...state.childItems,
                    skip: action.skip,
                    isLoading: true,
                },
            };
        case 'ITEM_DETAILS_CHILDREN_RECEIVE':
            return {
                ...state,
                childItems: {
                    ...state.childItems,
                    items: action.items,
                    count: action.count,
                    isLoading: false,
                },
            };
        case 'ITEM_DETAILS_CHILDREN_ERROR':
            return {
                ...state,
                childItems: {
                    ...state.childItems,
                    isLoading: false,
                },
            };

        case 'ITEM_DETAILS_CHILDREN_DELETE_START':
            return {
                ...state,
                childItems: {
                    ...state.childItems,
                    deleteStatus: ServerOperationStatus.READY,
                },
            };
        case 'ITEM_DETAILS_CHILDREN_DELETE_SEND':
            return {
                ...state,
                childItems: {
                    ...state.childItems,
                    deleteStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'ITEM_DETAILS_CHILDREN_DELETE_CANCEL':
            return {
                ...state,
                childItems: {
                    ...state.childItems,
                    deleteStatus: ServerOperationStatus.NONE,
                },
            };
        case 'ITEM_DETAILS_CHILDREN_DELETE_SUCCESS':
            return {
                ...state,
                childItems: {
                    ...state.childItems,
                    items: [
                        ...state.childItems.items.filter(x => !action.ids.includes(x.itemId)),
                    ],
                    deleteStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'ITEM_DETAILS_CHILDREN_DELETE_ERROR':
            return {
                ...state,
                childItems: {
                    ...state.childItems,
                    deleteStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'ITEM_DETAILS_TRANSACTION_HISTORY_REQUEST':
            return {
                ...state,
                transactionHistory: {
                    ...state.transactionHistory,
                    skip: action.skip,
                    isLoading: true,
                },
            };
        case 'ITEM_DETAILS_TRANSACTION_HISTORY_RECEIVE':
            return {
                ...state,
                transactionHistory: {
                    ...state.transactionHistory,
                    historyItems: action.histories,
                    count: action.count,
                    isLoading: false,
                },
            };
        case 'ITEM_DETAILS_TRANSACTION_HISTORY_ERROR':
            return {
                ...state,
                transactionHistory: {
                    ...state.transactionHistory,
                    isLoading: false,
                },
            };

        case 'ITEM_DETAILS_LOCATION_HISTORY_REQUEST':
            return {
                ...state,
                locationHistory: {
                    ...state.locationHistory,
                    skip: action.skip,
                    isLoading: true,
                },
            };
        case 'ITEM_DETAILS_LOCATION_HISTORY_RECEIVE':
            return {
                ...state,
                locationHistory: {
                    ...state.locationHistory,
                    locations: action.histories,
                    count: action.count,
                    isLoading: false,
                },
            };
        case 'ITEM_DETAILS_LOCATION_HISTORY_ERROR':
            return {
                ...state,
                locationHistory: {
                    ...state.locationHistory,
                    isLoading: false,
                },
            };

        case 'ITEM_DETAILS_PACKAGE_HISTORY_REQUEST':
            return {
                ...state,
                packageHistory: {
                    ...state.packageHistory,
                    skip: action.skip,
                    isLoading: true,
                },
            };
        case 'ITEM_DETAILS_PACKAGE_HISTORY_RECEIVE':
            return {
                ...state,
                packageHistory: {
                    ...state.packageHistory,
                    packages: action.histories,
                    count: action.count,
                    isLoading: false,
                },
            };
        case 'ITEM_DETAILS_PACKAGE_HISTORY_ERROR':
            return {
                ...state,
                packageHistory: {
                    ...state.packageHistory,
                    isLoading: false,
                },
            };

        case 'ITEM_DETAILS_ACTION_LOG_REQUEST':
            return {
                ...state,
                actionLogs: {
                    ...state.actionLogs,
                    skip: action.skip,
                    isLoading: true,
                },
            };
        case 'ITEM_DETAILS_ACTION_LOG_RECEIVE':
            return {
                ...state,
                actionLogs: {
                    ...state.actionLogs,
                    logs: action.logs,
                    count: action.count,
                    isLoading: false,
                },
            };
        case 'ITEM_DETAILS_ACTION_LOG_ERROR':
            return {
                ...state,
                actionLogs: {
                    ...state.actionLogs,
                    isLoading: false,
                },
            };

        case 'ITEM_DETAILS_CONDITIONS_REQUEST':
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    isLoading: true,
                },
            };
        case 'ITEM_DETAILS_CONDITIONS_RECEIVE':
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    conditions: action.conditions,
                    isLoading: false,
                },
            };
        case 'ITEM_DETAILS_CONDITIONS_ERROR':
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    isLoading: false,
                },
            };

        case 'ITEM_DETAILS_CONDITIONS_UPDATE_DATA':
            let cndIndex = state.conditions.conditions.findIndex(x => x.itemConditionImageId === action.data.itemConditionImageId);
            let newConditions = [...state.conditions.conditions];
            newConditions[cndIndex] = action.data;

            return {
                ...state,
                updatedField: undefined,
                conditions: {
                    ...state.conditions,
                    conditions: newConditions,
                    updateStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'ITEM_DETAILS_CONDITIONS_UPDATE_DATA_REQUEST':
            return {
                ...state,
                updatedField: action.field,
                conditions: {
                    ...state.conditions,
                    updateStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'ITEM_DETAILS_CONDITIONS_UPDATE_DATA_ERROR':
            return {
                ...state,
                updatedField: undefined,
                conditions: {
                    ...state.conditions,
                    updateStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'ITEM_DETAILS_CONDITIONS_CREATE_REQUEST':
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    isLoading: true,
                    createStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'ITEM_DETAILS_CONDITIONS_CREATE_SUCCESS':
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    conditions: [...state.conditions.conditions, action.entity],
                    isLoading: false,
                    createStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'ITEM_DETAILS_CONDITIONS_CREATE_ERROR':
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    isLoading: false,
                    createStatus: ServerOperationStatus.ERROR,
                }
            };

        case 'ITEM_DETAILS_CONDITIONS_DELETE_REQUEST':
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    isLoading: true,
                    deleteStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'ITEM_DETAILS_CONDITIONS_DELETE_SUCCESS':
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    conditions: state.conditions.conditions.filter(x => x.itemConditionImageId !== action.deletedId),
                    isLoading: false,
                    deleteStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'ITEM_DETAILS_CONDITIONS_DELETE_ERROR':
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    isLoading: false,
                    deleteStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_REQUEST':
            return {
                ...state,
                customsDocuments: {
                    ...state.customsDocuments,
                    skip: action.skip,
                    isLoading: true,
                },
            };
        case 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_RECEIVE':
            return {
                ...state,
                customsDocuments: {
                    ...state.customsDocuments,
                    documents: action.documents,
                    count: action.count,
                    isLoading: false,
                },
            };
        case 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_ERROR':
            return {
                ...state,
                customsDocuments: {
                    ...state.customsDocuments,
                    isLoading: false,
                },
            };

        case 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_UPDATE_DATA':
            let docIndex = state.customsDocuments.documents.findIndex(x => x.customsDocumentHistoryId === action.data.customsDocumentHistoryId);
            let newDocs = [...state.customsDocuments.documents];
            newDocs[docIndex] = action.data;

            return {
                ...state,
                updatedField: undefined,
                customsDocuments: {
                    ...state.customsDocuments,
                    documents: newDocs,
                    updateStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_UPDATE_DATA_REQUEST':
            return {
                ...state,
                updatedField: action.field,
                customsDocuments: {
                    ...state.customsDocuments,
                    updateStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_UPDATE_DATA_ERROR':
            return {
                ...state,
                updatedField: undefined,
                customsDocuments: {
                    ...state.customsDocuments,
                    updateStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_CREATE_REQUEST':
            return {
                ...state,
                customsDocuments: {
                    ...state.customsDocuments,
                    isLoading: true,
                    createStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_CREATE_SUCCESS':
            return {
                ...state,
                customsDocuments: {
                    ...state.customsDocuments,
                    isLoading: false,
                    createStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'ITEM_DETAILS_CUSTOMS_DOCUMENTS_CREATE_ERROR':
            return {
                ...state,
                customsDocuments: {
                    ...state.customsDocuments,
                    isLoading: false,
                    createStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'ITEM_DETAILS_SPLIT_REQUEST':
            return {
                ...state,
                splitStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'ITEM_DETAILS_SPLIT_SUCCESS':
            return {
                ...state,
                splitStatus: ServerOperationStatus.SUCCESS,
                data: {
                    ...state.data,
                    partsQty: action.partsQty,
                    piecesQty: action.piecesQty,
                    split: true,
                },
            };
        case 'ITEM_DETAILS_SPLIT_ERROR':
            return {
                ...state,
                splitStatus: ServerOperationStatus.ERROR,
            };
        case 'ITEM_DETAILS_ADDPACKAGES_REQUEST':
            return {
                ...state,
                addPackagesStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'ITEM_DETAILS_ADDPACKAGES_SUCCESS':
            return {
                ...state,
                addPackagesStatus: ServerOperationStatus.SUCCESS,
                data: {
                    ...state.data,
                    partsQty: action.partsQty,
                    piecesQty: action.piecesQty,
                },
            };
        case 'ITEM_DETAILS_ADDPACKAGES_ERROR':
            return {
                ...state,
                addPackagesStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};