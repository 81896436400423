const LocalizationKeys = {
    AccountMenu: {
        Logout: "Account.Menu.Logout",
        Reports: "Account.Menu.Reports",
        Settings: "Account.Menu.Settings",
        SOP: "Account.Menu.SOP",
    },
    AddressSelect: {
        SelectAnother: "AddressSelect.SelectAnother",
    },
    Appointments: {
        AppointmentForm: {
            ClientFeedback: "Appointments.AppointmentForm.ClientFeedback",
            ClientRepresentatives: "Appointments.AppointmentForm.ClientRepresentatives",
            CompanyRepresentatives: "Appointments.AppointmentForm.CompanyRepresentatives",
            CreatedDate: "Appointments.AppointmentForm.CreatedDate",
            Details: "Appointments.AppointmentForm.Details",
            DetailsTitle: "Appointments.AppointmentForm.DetailsTitle",
            EndDate: "Appointments.AppointmentForm.EndDate",
            MeetingLocation: "Appointments.AppointmentForm.MeetingLocation",
            OwnedBy: "Appointments.AppointmentForm.OwnedBy",
            Priority: "Appointments.AppointmentForm.Priority",
            ReminderDate: "Appointments.AppointmentForm.ReminderDate",
            Representatives: "Appointments.AppointmentForm.Representatives",
            Scheduling: "Appointments.AppointmentForm.Scheduling",
            StartDate: "Appointments.AppointmentForm.StartDate",
            Status: "Appointments.AppointmentForm.Status",
            Subject: "Appointments.AppointmentForm.Subject",
            Title: "Appointments.AppointmentForm.Title",
            Type: "Appointments.AppointmentForm.Type",
        },
    },
    ClientFile: {
        Headers: {
            Job: "ClientFile.Headers.Job",
            PO: "ClientFile.Headers.PO",
            POItems: "ClientFile.Header.POItems",
            Shipment: "ClientFile.Headers.Shipment",
            Shipments: "ClientFile.Header.Shipments",
            Transactions: "ClientFile.Headers.Transactions",
        },
        Shipping: {
            Delete: {
                Title: "CFShipping.Delete.Title",
                Text: "CFShipping.Delete.Text",
            },
            Toolbar: {
                NewShipment: "CFShipping.Grid.Toolbar.NewShipment",
            },
            UniqueId: "CFShipping.Grid.UniqueId",
            Reference: "CFShipping.Grid.Reference",
            Status: "CFShipping.Grid.Status",
            ETA: "CFShipping.Grid.ETA",
            ETD: "CFShipping.Grid.ETD",
        },
        Transactions: {
            Type: "CFTransactions.Grid.Type",
            InternalId: "CFTransactions.Grid.InternalId",
            ScheduledDate: "CFTransactions.Grid.ScheduledDate",
            ActualDate: "CFTransactions.Grid.ActualDate",
            ClientRef: "CFTransactions.Grid.ClientRef",
            InvoiceRef: "CFTransactions.Grid.InvoiceRef",
            Status: "CFTransactions.Grid.Status",
            Division: "CFTransactions.Grid.Division",
            Shipper: "CFTransactions.Grid.Shipper",
            Consignee: "CFTransactions.Grid.Consignee",
            Carrier: "CFTransactions.Grid.Carrier",
            OwnedBy: "CFTransactions.Grid.OwnedBy",
            CreationDate: "CFTransactions.Grid.CreationDate",
            ClientBillable: "CFTransactions.Grid.ClientBillable",
            ProjectRef: "CFTransactions.Grid.ProjectRef",
            Filters: {
                InternalId: "CFTransactions.Grid.Filters.InternalId",
                Item: "CFTransactions.Grid.Filters.Item",
                Shipper: "CFTransactions.Grid.Filters.Shipper",
                Consignee: "CFTransactions.Grid.Filters.Consignee",
                StartDate: "CFTransactions.Grid.Filters.StartDate",
                EndDate: "CFTransactions.Grid.Filters.EndDate",
                TrackingNumber: "CFTransactions.Grid.Filters.TrackingNumber",
                Trip: "CFTransactions.Grid.Filters.Trip",
                Project: "CFTransactions.Grid.Filters.Project",
            },
            Toolbar: {
                SetStatus: "CFTransactions.Grid.Toolbar.SetStatus",
                Calendar: "CFTransactions.Grid.Toolbar.Calendar",
            },
        },
    },
    Communication: {
        Details: {
            TitleNew: "Communication.Details.TitleNew",
            Title: "Communication.Details.Title",
            CreatedOn: "Communication.Details.CreatedOn",
            CreatedBy: "Communication.Details.CreatedBy",
            Type: "Communication.Details.Type",
            Notes: "Communication.Details.Notes"
        },
        Grid: {
            CreatedOn: "Communication.Grid.CreatedOn",
            Type: "Communication.Grid.Type",
            Notes: "Communication.Grid.Notes",
            User: "Communication.Grid.User",
            ParentObject: "Communication.Grid.ParentObject",
            Delete: {
                Text: "Communication.Grid.Delete.Text",
                Title: "Communication.Grid.Delete.Title",
            },
        },
    },
    CompanyModal: {
        Addresses: {
            AddressDetails: {
                AddressType: "CompanyModal.AddressDetails.AddressType",
                City: "CompanyModal.AddressDetails.City",
                Comments: "CompanyModal.AddressDetails.Comments",
                Country: "CompanyModal.AddressDetails.Country",
                DefaultFor: "CompanyModal.AddressDetails.DefaultFor",
                DefaultForBilling: "CompanyModal.AddressDetails.Billing",
                DefaultForMailing: "CompanyModal.AddressDetails.Mailing",
                DefaultForShipping: "CompanyModal.AddressDetails.Shipping",
                Disabled: "CompanyModal.AddressDetails.Disabled",
                State: "CompanyModal.AddressDetails.State",
                Street1: "CompanyModal.AddressDetails.Street1",
                Street2: "CompanyModal.AddressDetails.Street2",
                Street3: "CompanyModal.AddressDetails.Street3",
                Title: "CompanyModal.AddressDetails.Title",
                TitleNew: "CompanyModal.AddressDetails.TitleNew",
                UseFor: "CompanyModal.AddressDetails.UseFor",
                UseForBilling: "CompanyModal.AddressDetails.Billing",
                UseForMailing: "CompanyModal.AddressDetails.Mailing",
                UseForShipping: "CompanyModal.AddressDetails.Shipping",
                Zip: "CompanyModal.AddressDetails.ZIP",
            },
            AddressGrid: {
                Delete: {
                    Text: "CompanyModal.Addresses.Grid.Delete.Text",
                    Title: "CompanyModal.Addresses.Grid.Delete.Title",
                },
                Billing: "CompanyModal.Addresses.Grid.Billing",
                DeleteWarning: "CompanyModal.Addresses.Grid.DeleteWarning",
                Mailing: "CompanyModal.Addresses.Grid.Mailing",
                Shipping: "CompanyModal.Addresses.Grid.Shipping",
                Title: "CompanyModal.Addresses.Grid.Title",
                UseFor: "CompanyModal.Addresses.Grid.UseFor",
            },
            ContactDetails: {
                Email: "CompanyModal.ContactDetails.Email",
                Fax: "CompanyModal.ContactDetails.Fax",
                Mobile: "CompanyModal.ContactDetails.Mobile",
                Phone: "CompanyModal.ContactDetails.Phone",
                Title: "CompanyModal.ContactDetails.Title",
                TitleNew: "CompanyModal.ContactDetails.TitleNew",
                Web: "CompanyModal.ContactDetails.Web",
                UseFor: "CompanyModal.ContactDetails.UseFor",
                UseForBilling: "CompanyModal.ContactDetails.Billing",
                UseForGeneral: "CompanyModal.ContactDetails.General",
                UseForMailing: "CompanyModal.ContactDetails.Mailing",
                UseForShipping: "CompanyModal.ContactDetails.Shipping",
            },
            ContactGrid: {
                Delete: {
                    Text: "CompanyModal.Contacts.Grid.Delete.Text",
                    Title: "CompanyModal.Contacts.Grid.Delete.Title",
                },
                Billing: "CompanyModal.Contacts.Grid.Billing",
                DeleteWarning: "CompanyModal.Contacts.Grid.DeleteWarning",
                General: "CompanyModal.Contacts.Grid.General",
                Mailing: "CompanyModal.Contacts.Grid.Mailing",
                Shipping: "CompanyModal.Contacts.Grid.Shipping",
                Title: "CompanyModal.Contacts.Grid.Title",
                UseFor: "CompanyModal.Contacts.Grid.UseFor",
            },
            Warnings: {
                NewAddressOpened: "CompanyModal.Addresses.Warnings.NewAddressOpened",
                NewContactOpened: "CompanyModal.Addresses.Warnings.NewContactOpened",
            },
        },
        Appointments: {
            Filters: {
                DateFrom: "CompanyModal.Appointments.Filters.DateFrom",
                DateTo: "CompanyModal.Appointments.Filters.DateTo",
                Description: "CompanyModal.Appointments.Filters.Description",
                EndDate: "CompanyModal.Appointments.Filters.EndDate",
                StartDate: "CompanyModal.Appointments.Filters.StartDate",
                Status: "CompanyModal.Appointments.Filters.Status",
            },
            Grid: {
                FollowupAppointment: "CompanyModal.Appointments.Grid.FollowupAppointment",
                HasDocuments: "CompanyModal.Appointments.Grid.HasDocuments",
                OriginalAppointment: "CompanyModal.Appointments.Grid.OriginalAppointment",
                Owner: "CompanyModal.Appointments.Grid.Owner",
                ParentObject: "CompanyModal.Appointments.Grid.ParentObject",
                Priority: "CompanyModal.Appointments.Grid.Priority",
                StartDate: "CompanyModal.Appointments.Grid.StartDate",
                Status: "CompanyModal.Appointments.Grid.Status",
                Subject: "CompanyModal.Appointments.Grid.Subject",
            },
        },
        General: {
            BadPayer: "CompanyModal.BadPayer",
            Balance: "CompanyModal.Balance",
            Category: "CompanyModal.Category",
            Code: "CompanyModal.Code",
            CreatedBy: "CompanyModal.CreatedBy",
            CreatedOn: "CompanyModal.CreatedOn",
            CreditLimit: "CompanyModal.CreditLimit",
            Currency: "CompanyModal.Currency",
            Deactivated: "CompanyModal.Deactivated",
            DefaultTaxType: "CompanyModal.DefaultTaxType",
            EinNumber: "CompanyModal.EinNumber",
            InsuranceRequired: "CompanyModal.InsuranceRequired",
            LegalEntity: "CompanyModal.LegalEntity",
            LegalName: "CompanyModal.LegalName",
            Length: "CompanyModal.Length",
            LMCode: "CompanyModal.LMCode",
            PartyType: "CompanyModal.PartyType",
            Source: "CompanyModal.Source",
            TaxExempt: "CompanyModal.TaxExempt",
            Terms: "CompanyModal.Terms",
            VatNumber: "CompanyModal.VatNumber",
            Volume: "CompanyModal.Volume",
            WebViewInventory: "CompanyModal.WebViewInventory",
            Weight: "CompanyModal.Weight",
        },
        Notifications: {
            BillingInstructions: "CompanyModal.Notifications.BillingInstructions",
            Warning: "CompanyModal.Notifications.Warning",
        },
        Opportunities: {
            Filters: {
                Certainty: "CompanyModal.Opportunities.Filters.Certainty",
                DateFrom: "CompanyModal.Opportunities.Filters.DateFrom",
                DateTo: "CompanyModal.Opportunities.Filters.DateTo",
                RelevantFrom: "CompanyModal.Opportunities.Filters.RelevantFrom",
                RelevantTo: "CompanyModal.Opportunities.Filters.RelevantTo",
                Department: "CompanyModal.Opportunities.Filters.Department",
                Description: "CompanyModal.Opportunities.Filters.Description",
                ForecastTotal: "CompanyModal.Opportunities.Filters.ForecastTotal",
                JobType: "CompanyModal.Opportunities.Filters.JobType",
                Source: "CompanyModal.Opportunities.Filters.Source",
                Stage: "CompanyModal.Opportunities.Filters.Stage",
                Status: "CompanyModal.Opportunities.Filters.Status",
                Territory: "CompanyModal.Opportunities.Filters.Territory",
            },
            Grid: {
                Certainty: "CompanyModal.Opportunities.Grid.Certainty",
                Description: "CompanyModal.Opportunities.Grid.Description",
                ForecastTotal: "CompanyModal.Opportunities.Grid.ForecastTotal",
                OwnedBy: "CompanyModal.Opportunities.Grid.OwnedBy",
                Priority: "CompanyModal.Opportunities.Grid.Priority",
                RelevantTill: "CompanyModal.Opportunities.Grid.RelevantTill",
                Stage: "CompanyModal.Opportunities.Grid.Stage",
                Status: "CompanyModal.Opportunities.Grid.Status",
                UniqueId: "CompanyModal.Opportunities.Grid.UniqueId",
            }
        },
        Persons: {
            Grid: {
                Name: "CompanyModal.Persons.Grid.Name",
                Address: "CompanyModal.Persons.Grid.Address",
                Type: "CompanyModal.Persons.Grid.Type",
                General: "CompanyModal.Persons.Grid.General",
                Mailing: "CompanyModal.Persons.Grid.Mailing",
                Billing: "CompanyModal.Persons.Grid.Billing",
                Shipping: "CompanyModal.Persons.Grid.Shipping",
                Delete: {
                    Text: "CompanyModal.Persons.Grid.Delete.Text",
                    Title: "CompanyModal.Persons.Grid.Delete.Title",
                },
                Filters: {
                    Address: "CompanyModal.Persons.Grid.Filters.Address",
                    Search: "CompanyModal.Persons.Grid.Filters.Search",
                },
                Toolbar: {
                    CreateWebUser: "CompanyModal.Persons.Grid.Toolbar.CreateWebUser",
                    Initialize: "CompanyModal.Persons.Grid.Toolbar.Initialize",
                },
            },
            PersonDetails: {
                FirstName: "CompanyModal.Persons.Details.FirstName",
                LastName: "CompanyModal.Persons.Details.LastName",
                Salutation: "CompanyModal.Persons.Details.Salutation",
                PersonRole: "CompanyModal.Persons.Details.PersonRole",
                Title: "CompanyModal.Persons.Details.Title",
                TitleNew: "CompanyModal.Persons.Details.TitleNew",
                Type: "CompanyModal.Persons.Details.Type",
                IsGeneralType: "CompanyModal.Persons.Details.IsGeneralType",
                IsMailingType: "CompanyModal.Persons.Details.IsMailingType",
                IsShippingType: "CompanyModal.Persons.Details.IsShippingType",
                IsBillingType: "CompanyModal.Persons.Details.IsBillingType",
                Address: "CompanyModal.Persons.Details.Address",
                Contact: "CompanyModal.Persons.Details.Contact",
                Phone: "CompanyModal.Persons.Details.Phone",
                MobilePhone: "CompanyModal.Persons.Details.MobilePhone",
                Fax: "CompanyModal.Persons.Details.Fax",
                Email: "CompanyModal.Persons.Details.Email",
            },
            Warnings: {
                NewPersonOpened: "CompanyModal.Persons.Warnings.NewPersonOpened",
            },
            Title: "CompanyModal.Persons.Title",
        },
        Quotations: {
            Filters: {
                CustomsEntryNr: "CompanyModal.Quotations.Filters.CustomsEntryNr",
                Description: "CompanyModal.Quotations.Filters.Description",
                Division: "CompanyModal.Quotations.Filters.Division",
                EndDate: "CompanyModal.Quotations.Filters.EndDate",
                ItemDescription: "CompanyModal.Quotations.Filters.ItemDescription",
                ItemObjectNr: "CompanyModal.Quotations.Filters.ItemObjectNr",
                Lot: "CompanyModal.Quotations.Filters.Lot",
                QuotationsNr: "CompanyModal.Quotations.Filters.QuotationNr",
                Sidemark: "CompanyModal.Quotations.Filters.SideMark",
                StartDate: "CompanyModal.Quotations.Filters.StartDate",
                User: "CompanyModal.Quotations.Filters.User",
            },
            Grid: {
                ClientRef: "CompanyModal.Quotations.Grid.ClientRef",
                ConsigneeCity: "CompanyModal.Quotations.Grid.ConsigneeCity",
                ConsigneeCountry: "CompanyModal.Quotations.Grid.ConsigneeCountry",
                CreatedBy: "CompanyModal.Quotations.Grid.CreatedBy",
                CreatedOn: "CompanyModal.Quotations.Grid.CreatedOn",
                Description: "CompanyModal.Quotations.Grid.Description",
                JobId: "CompanyModal.Quotations.Grid.JobId",
                ShipperCity: "CompanyModal.Quotations.Grid.ShipperCity",
                ShipperCountry: "CompanyModal.Quotations.Grid.ShipperCountry",
                Status: "CompanyModal.Quotations.Grid.Status",
                Total: "CompanyModal.Quotations.Grid.Total",
                UniqueId: "CompanyModal.Quotations.Grid.UniqueId",
            },
        },
        Tabs: {
            Appointments: "CompanyModal.Tabs2.Appointments",
            Communications: "CompanyModal.Tabs2.Communications",
            ContactsAddresses: "CompanyModal.Tabs2.ContactsAddresses",
            DocumentHistory: "CompanyModal.Tabs.DocumentHistory",
            General: "CompanyModal.Tabs.General",
            Notifications: "CompanyModal.Tabs.Notifications",
            Opportunities: "CompanyModal.Tabs2.Opportunities",
            Persons: "CompanyModal.Tabs2.Persons",
            Quotations: "CompanyModal.Tabs2.Quotations",
            Tasks: "CompanyModal.Tabs2.Tasks",
        },
        Tasks: {
            Filters: {
                DateFrom: "CompanyModal.Tasks.Filters.DateFrom",
                DateTo: "CompanyModal.Tasks.Filters.DateTo",
                Description: "CompanyModal.Tasks.Filters.Description",
                EndDate: "CompanyModal.Tasks.Filters.EndDate",
                StartDate: "CompanyModal.Tasks.Filters.StartDate",
                Status: "CompanyModal.Tasks.Filters.Status",
            },
            Grid: {
                DueDate: "CompanyModal.Tasks.Grid.DueDate",
                FollowupTask: "CompanyModal.Tasks.Grid.FollowupTask",
                HasDocuments: "CompanyModal.Tasks.Grid.HasDocuments",
                OriginalTask: "CompanyModal.Tasks.Grid.OriginalTask",
                Owner: "CompanyModal.Tasks.Grid.Owner",
                ParentObject: "CompanyModal.Tasks.Grid.ParentObject",
                Priority: "CompanyModal.Tasks.Grid.Priority",
                StartDate: "CompanyModal.Tasks.Grid.StartDate",
                Status: "CompanyModal.Tasks.Grid.Status",
                Subject: "CompanyModal.Tasks.Grid.Subject",
            },
        },
        Title: "CompanyModal.Title",
    },
    ContainerModal: {
        AirwayBill: "ContainerModal.AirwayBill",
        Barcode: "ContainerModal.Barcode",
        BillOfLading: "ContainerModal.BillOfLading",
        BookingRef: "ContainerModal.BookingRef",
        ChargeableVolume: "ContainerModal.ChargeableVolume",
        ContainerType: "ContainerModal.ContainerType",
        GrossVolume: "ContainerModal.GrossVolume",
        GrossWeight: "ContainerModal.GrossWeight",
        Height: "ContainerModal.Height",
        Length: "ContainerModal.Length",
        LoadType: "ContainerModal.LoadType",
        NetVolume: "ContainerModal.NetVolume",
        NetWeight: "ContainerModal.NetWeight",
        NumberOfPieces: "ContainerModal.NumberOfPieces",
        PhotoRef: "ContainerModal.PhotoRef",
        SealNumber: "ContainerModal.SealNumber",
        SerialNumber: "ContainerModal.SerialNumber",
        TareWeight: "ContainerModal.TareWeight",
        Title: "ContainerModal.Title",
        Width: "ContainerModal.Width",
    },
    CRM: {
        Appointments: {
            Filters: {
                Description: "CRM.Appointments.Filters.Description",
                OwnedBy: "CRM.Appointments.Filters.OwnedBy",
                Status: "CRM.Appointments.Filters.Status",
                DateFrom: "CRM.Appointments.Filters.DateFrom",
                DateTo: "CRM.Appointments.Filters.DateTo",
                StartDate: "CRM.Appointments.Filters.StartDate",
                EndDate: "CRM.Appointments.Filters.EndDate",
            },
            Grid: {
                StartDate: "CRM.Appointments.Grid.StartDate",
                Owner: "CRM.Appointments.Grid.Owner",
                Subject: "CRM.Appointments.Grid.Subject",
                Priority: "CRM.Appointments.Grid.Priority",
                Status: "CRM.Appointments.Grid.Status",
                OpportunityDescription: "CRM.Appointments.Grid.OpportunityDescription",
                OpportunityCompany: "CRM.Appointments.Grid.OpportunityCompany",
                OriginalAppointment: "CRM.Appointments.Grid.OriginalAppointment",
                FollowUpAppointment: "CRM.Appointments.Grid.FollowUpAppointment",
                HasDocuments: "CRM.Appointments.Grid.HasDocuments",
            },
        },
        Campaigns: {
            Filters: {
                CampaignName: "CRM.Campaigns.Filters.CampaignName",
                Description: "CRM.Campaigns.Filters.Description",
                CampaignType: "CRM.Campaigns.Filters.CampaignType",
                OwnedBy: "CRM.Campaigns.Filters.OwnedBy",
                CreatedFrom: "CRM.Campaigns.Filters.CreatedFrom",
                CreatedTo: "CRM.Campaigns.Filters.CreatedTo",
                ExecutedFrom: "CRM.Campaigns.Filters.ExecutedFrom",
                ExecutedTo: "CRM.Campaigns.Filters.ExecutedTo",
                Status: "CRM.Campaigns.Filters.Status",
            },
            Grid: {
                Name: "CRM.Campaigns.Grid.Name",
                Description: "CRM.Campaigns.Grid.Description",
                Type: "CRM.Campaigns.Grid.Type",
                OwnedBy: "CRM.Campaigns.Grid.OwnedBy",
                CreationDate: "CRM.Campaigns.Grid.CreationDate",
                Status: "CRM.Campaigns.Grid.Status",
                ExecutionDate: "CRM.Campaigns.Grid.ExecutionDate",
                Toolbar: {
                    NewCampaign: "CRM.Campaigns.Grid.Toolbar.NewCampaign",
                    Clone: "CRM.Campaigns.Grid.Toolbar.Clone",
                },
            },
            New: {
                Title: "CRM.Campaigns.NewCampaign.Title",
                CampaignName: "CRM.Campaigns.NewCampaign.CampaignName",
            },
        },
        CampaignDetails: {
            Companies: {
                Filters: {
                    CompanyCode: "CampaignDetails.Search.Company.CompanyCode",
                    CompanyName: "CampaignDetails.Search.Company.CompanyName",
                    CompanyType: "CampaignDetails.Search.Company.CompanyType",
                    CompanyCategory: "CampaignDetails.Search.Company.CompanyCategory",
                    CompanyCountry: "CampaignDetails.Search.Company.CompanyCountry",
                    CompanyState: "CampaignDetails.Search.Company.CompanyState",
                    CompanyCity: "CampaignDetails.Search.Company.CompanyCity",
                    ContactType: "CampaignDetails.Search.Company.ContactType",
                },
            },
        },
        Companies: {
            Filters: {
                From: "CRM.Companies.Filters.From",
                To: "CRM.Companies.Filters.To",
                CodeName: "CRM.Companies.Filters.CodeName",
                Address: "CRM.Companies.Filters.Address",
                State: "CRM.Companies.Filters.State",
                Country: "CRM.Companies.Filters.Country",
                Type: "CRM.Companies.Filters.Type",
                Category: "CRM.Companies.Filters.Category",
            },
            Grid: {
                Code: "CRM.Companies.Grid.Code",
                LegalName: "CRM.Companies.Grid.LegalName",
                Type: "CRM.Companies.Grid.Type",
                Warning: "CRM.Companies.Grid.Warning",
                BillingInstructions: "CRM.Companies.Grid.BillingInstructions",
                Deactivated: "CRM.Companies.Grid.Deactivated",
                BadPayer: "CRM.Companies.Grid.BadPayer",
                Toolbar: {
                    New: "CRM.Companies.Grid.Toolbar.NewCompany",
                },
            },
        },
        Opportunities: {
            Delete: {
                Title: "CRM.Opportunities.Delete.Title",
                Text: "CRM.Opportunities.Delete.Text",
            },
            Filters: {
                Description: "CRM.Opportunities.Filters.Description",
                Source: "CRM.Opportunities.Filters.Source",
                JobType: "CRM.Opportunities.Filters.JobType",
                Territory: "CRM.Opportunities.Filters.Territory",
                Department: "CRM.Opportunities.Filters.Department",
                Stage: "CRM.Opportunities.Filters.Stage",
                Status: "CRM.Opportunities.Filters.Status",
                ForecastTotal: "CRM.Opportunities.Filters.ForecastTotal",
                Certainty: "CRM.Opportunities.Filters.Certainty",
                CreatedFrom: "CRM.Opportunities.Filters.CreatedFrom",
                CreatedTo: "CRM.Opportunities.Filters.CreatedTo",
                RelevantTillFrom: "CRM.Opportunities.Filters.RelevantTillFrom",
                RelevantTillTo: "CRM.Opportunities.Filters.RelevantTillTo",
            },
            Grid: {
                UniqueId: "CRM.Opportunities.Grid.UniqueId",
                LeadName: "CRM.Opportunities.Grid.LeadName",
                OwnedBy: "CRM.Opportunities.Grid.OwnedBy",
                Description: "CRM.Opportunities.Grid.Description",
                ExpireOn: "CRM.Opportunities.Grid.ExpireOn",
                Stage: "CRM.Opportunities.Grid.Stage",
                Status: "CRM.Opportunities.Grid.Status",
                ForecastTotal: "CRM.Opportunities.Grid.ForecastTotal",
                Certainty: "CRM.Opportunities.Grid.Certainty",
                Priority: "CRM.Opportunities.Grid.Priority",
                Toolbar: {
                    New: "CRM.Opportunities.Grid.Toolbar.NewOpportunity",
                },
            },
            New: {
                Title: "CRM.Opportunities.NewOpportunity.Title",
                Company: "CRM.Opportunities.NewOpportunity.Company",
                Contact: "CRM.Opportunities.NewOpportunity.Contact",
                Description: "CRM.Opportunities.NewOpportunity.Description",
                RelevantTill: "CRM.Opportunities.NewOpportunity.RelevantTill",
            },
        },
        Recent: {
            Appointments: {
                CreatedOn: "CRM.Recent.Appointments.Grid.CreatedOn",
                OwnedBy: "CRM.Recent.Appointments.Grid.OwnedBy",
                CompanyName: "CRM.Recent.Appointments.Grid.CompanyName",
                Subject: "CRM.Recent.Appointments.Grid.Subject",
                Details: "CRM.Recent.Appointments.Grid.Details",
            },
            Communications: {
                CreatedOn: "CRM.Recent.Communications.Grid.CreatedOn",
                CreatedBy: "CRM.Recent.Communications.Grid.CreatedBy",
                Notes: "CRM.Recent.Communications.Grid.Notes",
                ParentObject: "CRM.Recent.Communications.Grid.ParentObject",
            },
            Companies: {
                CompanyCode: "CRM.Recent.Companies.Grid.CompanyCode",
                LegalName: "CRM.Recent.Companies.Grid.LegalName",
            },
            Contacts: {
                CompanyName: "CRM.Recent.Contacts.Grid.CompanyName",
                FullName: "CRM.Recent.Contacts.Grid.FullName",
            },
            Documents: {
                CreatedOn: "CRM.Recent.Documents.Grid.CreatedOn",
                CreatedBy: "CRM.Recent.Documents.Grid.CreatedBy",
                Title: "CRM.Recent.Documents.Grid.Title",
                Filename: "CRM.Recent.Documents.Grid.Filename",
                ParentObject: "CRM.Recent.Documents.Grid.ParentObject",
            },
            Filters: {
                Users: "CRM.Recent.Filters.Users",
                DateFrom: "CRM.Recent.Filters.DateFrom",
                DateTo: "CRM.Recent.Filters.DateTo",
            },
            Opportunities: {
                CreatedOn: "CRM.Recent.Opportunities.Grid.CreatedOn",
                OwnedBy: "CRM.Recent.Opportunities.Grid.OwnedBy",
                CompanyName: "CRM.Recent.Opportunities.Grid.CompanyName",
                Description: "CRM.Recent.Opportunities.Grid.Description",
            },
            Tabs: {
                Companies: "CRM.Recent.Tabs.Companies",
                Contacts: "CRM.Recent.Tabs.Contacts",
                Opportunities: "CRM.Recent.Tabs.Opportunities",
                Tasks: "CRM.Recent.Tabs.Tasks",
                Appointments: "CRM.Recent.Tabs.Appointments",
                Communications: "CRM.Recent.Tabs.Communications",
                Documents: "CRM.Recent.Tabs.Documents",
            },
            Tasks: {
                CreatedOn: "CRM.Recent.Tasks.Grid.CreatedOn",
                OwnedBy: "CRM.Recent.Tasks.Grid.OwnedBy",
                CompanyName: "CRM.Recent.Tasks.Grid.CompanyName",
                Subject: "CRM.Recent.Tasks.Grid.Subject",
                Details: "CRM.Recent.Tasks.Grid.Details",
            },
        },
        Tabs: {
            Opportunities: "CRM.Tabs.Opportunities",
            Companies: "CRM.Tabs.Companies",
            Tasks: "CRM.Tabs.Tasks",
            Appointments: "CRM.Tabs.Appointments",
            Calendar: "CRM.Tabs.Calendar",
            Campaigns: "CRM.Tabs.Campaigns",
            RecentlyAdded: "CRM.Tabs.RecentlyAdded",
        },
        Tasks: {
            Filters: {
                Description: "CRM.Tasks.Filters.Description",
                OwnedBy: "CRM.Tasks.Filters.OwnedBy",
                Status: "CRM.Tasks.Filters.Status",
                DateFrom: "CRM.Tasks.Filters.DateFrom",
                DateTo: "CRM.Tasks.Filters.DateTo",
                StartDate: "CRM.Tasks.Filters.StartDate",
                DueDate: "CRM.Tasks.Filters.DueDate",
            },
            Grid: {
                StartDate: "CRM.Tasks.Grid.StartDate",
                DueDate: "CRM.Tasks.Grid.DueDate",
                Owner: "CRM.Tasks.Grid.Owner",
                Subject: "CRM.Tasks.Grid.Subject",
                Priority: "CRM.Tasks.Grid.Priority",
                Status: "CRM.Tasks.Grid.Status",
                OpportunityDescription: "CRM.Tasks.Grid.OpportunityDescription",
                OpportunityCompany: "CRM.Tasks.Grid.OpportunityCompany",
                OpportunityContact: "CRM.Tasks.Grid.OpportunityContact",
                OriginalTask: "CRM.Tasks.Grid.OriginalTask",
                FollowUpTask: "CRM.Tasks.Grid.FollowUpTask",
                HasDocuments: "CRM.Tasks.Grid.HasDocuments",
            },
        },
    },
    DocumentHistory: {
        Grid: {
            Processed: "DocumentHistory.Grid.Processed",
            Document: "DocumentHistory.Grid.Document",
            Title: "DocumentHistory.Grid.Title",
            ProcessedBy: "DocumentHistory.Grid.ProcessedBy",
            ProcessedOn: "DocumentHistory.Grid.ProcessedOn",
            CreatedOn: "DocumentHistory.Grid.CreatedOn",
        },
        Toolbar: {
            Add: "DocumentHistory.Grid.Toolbar.Add",
            Process: "DocumentHistory.Grid.Toolbar.Process",
            Delete: "DocumentHistory.Grid.Toolbar.Delete",
            Search: "DocumentHistory.Grid.Toolbar.Search",
        },
    },
    Financials: {
        Filters: {
            StartDate: "Financials.Filters.StartDate",
            EndDate: "Financials.Filters.EndDate",
            Entity: "Financials.Filters.Entity",
            Client: "Financials.Filters.Client",
            ClientRef: "Financials.Filters.ClientRef",
            JobId: "Financials.Filters.JobId",
            BillTo: "Financials.Filters.BillTo",
            JobType: "Financials.Filters.JobType",
            InvoiceJobType: "Financials.Filters.InvoiceJobType",
            TransportMode: "Financials.Filters.TransportMode",
            TotalWithTax: "Financials.Filters.TotalWithTax",
            InvoiceNr: "Financials.Filters.InvoiceNr",
            Division: "Financials.Filters.Division",
            User: "Financials.Filters.User",
            GLCode: "Financials.Filters.GLCode",
            Trip: "Financials.Filters.Trip",
            Filter: "Financials.Filters.Filter",
            Status: "Financials.Filters.Status",
        },
        Grid: {
            InvoiceNr: "Financials.Grid.InvoiceNr",
            OwnedBy: "Financials.Grid.OwnedBy",
            UpdatedBy: "Financials.Grid.UpdatedBy",
            UpdatedDate: "Financials.Grid.UpdatedDate",
            JobType: "Financials.Grid.JobType",
            BillingDivision: "Financials.Grid.BillingDivision",
            BillingParty: "Financials.Grid.BillingParty",
            BillingPartyCode: "Financials.Grid.BillingPartyCode",
            BillingDate: "Financials.Grid.BillingDate",
            Attn: "Financials.Grid.Attn",
            TotalAfterTax: "Financials.Grid.TotalAfterTax",
            Status: "Financials.Grid.Status",
            IsPaid: "Financials.Grid.IsPaid",
            InvoiceType: "Financials.Grid.InvoiceType",
            IsDeleted: "Financials.Grid.IsDeleted",
            Toolbar: {
                CloneInvoice: "Financials.Grid.Toolbar.CloneInvoice",
                Finalize: "Financials.Grid.Toolbar.Finalize",
                FinalizeAll: "Financials.Grid.Toolbar.FinalizeAll",
                FlagAsPaid: "Financials.Grid.Toolbar.FlagAsPaid",
                AccountingSync: "Financials.Grid.Toolbar.AccountingSync",
            },
        }
    },
    Gallery: {
        Title: "Gallery.Title",
        DownloadAll: "Gallery.DownloadAll",
        NoPhotos: "Gallery.NoPhotos",
        AddPhotos: "Gallery.AddPhotos",
        Modal: {
            Next: "Gallery.Modal.Next",
            Previous: "Gallery.Modal.Previous",
            Title: "Gallery.Modal.Title",
            Upload: "Gallery.Modal.Upload",
            MakeFirst: "Gallery.Modal.MakeFirst",
            ReplaceImage: "Gallery.Modal.ReplaceImage",
            RotateLeft: "Gallery.Modal.RotateLeft",
            RotateRight: "Gallery.Modal.RotateRight",
            DropHere: "Gallery.Modal.DropHere",
            Splitter: "Gallery.Modal.Splitter",
        },
        Errors: {
            InvalidExtension: "Gallery.Errors.InvalidExtension",
            InvalidFileSize: "Gallery.Errors.InvalidFileSize",
        }
    },
    GeneralInfo: {
        Booking: {
            Booker: "GeneralInfo.Booking.Booker",
            ServiceType: "GeneralInfo.Job.ServiceType",
            Contact: "GeneralInfo.Booking.Contact",
            ServiceSubType: "GeneralInfo.Job.ServiceSubType",
            QuotedTo: "GeneralInfo.Booking.QuotedTo",
            GroupageNumber: "GeneralInfo.Job.GroupageNumber",
            Revenue: "GeneralInfo.Job.Revenue",
        },
        Client: {
            Client: "GeneralInfo.Client.Client",
            Address: "GeneralInfo.Client.Address",
            Contact: "GeneralInfo.Client.Contact",
            Phone: "GeneralInfo.Client.Phone",
            Mobile: "GeneralInfo.Client.Mobile",
            Fax: "GeneralInfo.Client.Fax",
            Email: "GeneralInfo.Client.Email",
        },
        ClientWarning: "GeneralInfo.ClientWarning.Title",
        Comments: {
            Title: "GeneralInfo.Comments.Title",
            FileReminder: "GeneralInfo.Comments.FileReminder",
            Comments: "GeneralInfo.Comments.Comments",
        },
        Insurance: {
            Title: "GeneralInfo.Insurance.Title",
            InsuranceRequired: "GeneralInfo.Insurance.InsuranceRequired",
            CIF: "GeneralInfo.Insurance.CIF",
            Value: "GeneralInfo.Insurance.Value",
            DeclinedOn: "GeneralInfo.Insurance.DeclinedOn",
            DeclinedBy: "GeneralInfo.Insurance.DeclinedBy",
        },
        Job: {
            CurrentDivision: "GeneralInfo.Job.CurrentDivision",
            NextDivision: "GeneralInfo.Job.NextDivision",
            CreatedOn: "GeneralInfo.Job.CreatedOn",
            ClosedOn: "GeneralInfo.Job.ClosedOn",
            Status: "GeneralInfo.Job.Status",
            OwnedBy: "GeneralInfo.Job.OwnedBy",
            JobType: "GeneralInfo.Job.JobType",
            BilledTo: "GeneralInfo.Job.BilledTo",
            ClientRef1: "GeneralInfo.Client.ClientRef1",
            ClientRef2: "GeneralInfo.Client.ClientRef2",
        },
        Shipping: {
            Title: "GeneralInfo.Shipping.Title",
            Shipper: "GeneralInfo.Shipping.Shipper",
            ShipperAddress: "GeneralInfo.Shipping.Address",
            ShipperContact: "GeneralInfo.Shipping.ShipperContact",
            Consignee: "GeneralInfo.Shipping.Consignee",
            ConsigneeAddress: "GeneralInfo.Consignee.Address",
            ConsigneeContact: "GeneralInfo.Shipping.ConsigneeContact",
            Route: "GeneralInfo.Shipping.Route",
            AlwaysShow: "GeneralInfo.Shipping.AlwaysShow",
        },
        Tabs: {
            Booking: "GeneralInfo.Tabs.Booking",
            Main: "GeneralInfo.Tabs.Main",
        },
    },
    Inventory: {
        Items: {
            Filters: {
                UniqueId: "Inventory.Filters.UniqueId",
                LocationId: "Inventory.Filters.LocationId",
                Lot: "Inventory.Filters.Lot",
                Sidemark: "Inventory.Filters.Sidemark",
                Description: "Inventory.Filters.Description",
                Owner: "Inventory.Filters.Owner",
                Client: "Inventory.Filters.Client",
                Dealer: "Inventory.Filters.Dealer",
                Division: "Inventory.Filters.Division",
                CustomsEntry: "Inventory.Filters.CustomsEntry",
                RoomDepartment: "Inventory.Filters.RoomDepartment",
                CustomsStatus: "Inventory.Filters.CustomsStatus",
                StartDate: "Inventory.Filters.StartDate",
                EndDate: "Inventory.Filters.EndDate",
                InCareOnly: "Inventory.Filters.InCareOnly",
                HideParents: "Inventory.Filters.HideParents",
                ShowFullHistory: "Inventory.Filters.ShowFullHistory",
            },
            Grid: {
                ItemNr: "Inventory.Grid.ItemNr",
                Description: "Inventory.Grid.Description",
                Depth: "Inventory.Grid.Depth",
                Width: "Inventory.Grid.Width",
                Height: "Inventory.Grid.Height",
                ExtDepth: "Inventory.Grid.ExtDepth",
                ExtWidth: "Inventory.Grid.ExtWidth",
                ExtHeight: "Inventory.Grid.ExtHeight",
                Sidemark: "Inventory.Grid.Sidemark",
                Lot: "Inventory.Grid.Lot",
                WarehousePosition: "Inventory.Grid.WarehousePosition",
                PONumber: "Inventory.Grid.PONumber",
                Dealer: "Inventory.Grid.Dealer",
                Artist: "Inventory.Grid.Artist",
                Title: "Inventory.Grid.Title",
                Job: "Inventory.Grid.Job",
                CustomsStatus: "Inventory.Grid.CustomsStatus",
                Crate: "Inventory.Grid.Crate",
                InsuranceValue: "Inventory.Grid.InsuranceValue",
                Location: "Inventory.Grid.Location",
                DateIn: "Inventory.Grid.DateIn",
                DateOut: "Inventory.Grid.DateOut",
                HasPhotos: "Inventory.Grid.HasPhotos",
                Parent: "Inventory.Grid.Parent",
            },
        },
        Packages: {
            Filters: {
                PackageUniqueId: "Inventory.Filters.PackageUniqueId",
                PackageLocationId: "Inventory.Filters.PackageLocationId",
                PackageDescription: "Inventory.Filters.PackageDescription",
            },
            Grid: {
                PackageNr: "Inventory.Grid.Package.PackageNr",
                LabelNumber: "Inventory.Grid.Package.LabelNumber",
                PackageType: "Inventory.Grid.Package.PackageType",
                IsCrate: "Inventory.Grid.Package.IsCrate",
                Location: "Inventory.Grid.Package.Location",
                DateIn: "Inventory.Grid.Package.DateIn",
                DateOut: "Inventory.Grid.Package.DateOut",
            },
        },
        Tabs: {
            Items: "Inventory.Tabs.Items",
            Packages: "Inventory.Tabs.Packages",
            EmptyCrates: "Inventory.Tabs.EmptyCrates",
        },
    },
    ItemDetails: {
        ActionLog: {
            ActionDate: "ItemDetails.ActionLog.ActionDate",
            Item: "ItemDetails.ActionLog.Item",
            Description: "ItemDetails.ActionLog.Description",
            ActionType: "ItemDetails.ActionLog.ActionType",
            RelatedItem: "ItemDetails.ActionLog.RelatedItem",
            Username: "ItemDetails.ActionLog.Username",
            Title: "ItemDetails.ActionLog.Title",
        },
        Conditions: {
            Position: "ItemDetails.Conditions.Position",
            Type: "ItemDetails.Conditions.Type",
            Location: "ItemDetails.Conditions.Location",
            CreatedDate: "ItemDetails.Conditions.CreatedDate",
            Title: "ItemDetails.Conditions.Title",
            Details: {
                Position: "ItemDetails.Conditions.Details.Position",
                Type: "ItemDetails.Conditions.Details.Type",
                Location: "ItemDetails.Conditions.Details.Location",
                Notes: "ItemDetails.Conditions.Details.Notes",
            },
            Delete: {
                Title: "ItemDetails.Conditions.Delete.Title",
                Text: "ItemDetails.Conditions.Delete.Text",
            },
        },
        CustomsDocuments: {
            CreatedOn: "ItemDetails.CustomsDocuments.CreatedOn",
            JobFile: "ItemDetails.CustomsDocuments.JobFile",
            ItemNr: "ItemDetails.CustomsDocuments.ItemNr",
            PrevStatus: "ItemDetails.CustomsDocuments.PrevStatus",
            CurrentStatus: "ItemDetails.CustomsDocuments.CurrentStatus",
            DocType: "ItemDetails.CustomsDocuments.DocType",
            Afc: "ItemDetails.CustomsDocuments.Afc",
            DocNr: "ItemDetails.CustomsDocuments.DocNr",
            ExpiryDate: "ItemDetails.CustomsDocuments.ExpiryDate",
            IssuedBy: "ItemDetails.CustomsDocuments.IssuedBy",
            CountryOfOriginOrDestination: "ItemDetails.CustomsDocuments.CountryOfOriginOrDestination",
            CustomsOfficeDepartureOrArrival: "ItemDetails.CustomsDocuments.CustomsOfficeDepartureOrArrival",
            CustomsOfficeDeparture: "ItemDetails.CustomsDocuments.CustomsOfficeDeparture",
            CountryOfOrigin: "ItemDetails.CustomsDocuments.CountryOfOrigin",
            CustomsOfficeArrival: "ItemDetails.CustomsDocuments.CustomsOfficeArrival",
            CountryOfDestination: "ItemDetails.CustomsDocuments.CountryOfDestination",
            TransferDocNr: "ItemDetails.CustomsDocuments.TransferDocNr",
            TempExtensionNr: "ItemDetails.CustomsDocuments.TempExtensionNr",
            DocReplaced: "ItemDetails.CustomsDocuments.DocReplaced",
            DocReplacedDate: "ItemDetails.CustomsDocuments.DocReplacedDate",
            TempDocSentTo: "ItemDetails.CustomsDocuments.TempDocSentTo",
            ReceivedFromName: "ItemDetails.CustomsDocuments.ReceivedFromName",
            ReleasedToName: "ItemDetails.CustomsDocuments.ReleasedToName",
            Id: "ItemDetails.CustomsDocuments.Id",
            Replace: "ItemDetails.CustomsDocuments.Replace",
            AssignItems: "ItemDetails.CustomsDocuments.AssignItems",
            Title: "ItemDetails.CustomsDocuments.Title",
            Details: {
                ItemDetails: "ItemDetails.CustomsDocuments.Details.ItemDetails",
                OriginDestination: "ItemDetails.CustomsDocuments.Details.OriginDestination",
                JobFile: "ItemDetails.CustomsDocuments.Details.JobFile",
                ItemNr: "ItemDetails.CustomsDocuments.Details.ItemNr",
                DocNr: "ItemDetails.CustomsDocuments.Details.DocNr",
                DocType: "ItemDetails.CustomsDocuments.Details.DocType",
                CreationDate: "ItemDetails.CustomsDocuments.Details.CreationDate",
                ExpiryDate: "ItemDetails.CustomsDocuments.Details.ExpiryDate",
                PrevStatus: "ItemDetails.CustomsDocuments.Details.PrevStatus",
                CurrentStatus: "ItemDetails.CustomsDocuments.Details.CurrentStatus",
                TransitDocNr: "ItemDetails.CustomsDocuments.Details.TransitDocNr",
                DocIssuedBy: "ItemDetails.CustomsDocuments.Details.DocIssuedBy",
                AFC: "ItemDetails.CustomsDocuments.Details.AFC",
                CustomsOfficeLocationDeparture: "ItemDetails.CustomsDocuments.Details.CustomsOfficeLocationDeparture",
                CountryOfOrigin: "ItemDetails.CustomsDocuments.Details.CountryOfOrigin",
                CustomsOfficeLocationDestination: "ItemDetails.CustomsDocuments.Details.CustomsOfficeLocationDestination",
                CountryOfDestination: "ItemDetails.CustomsDocuments.Details.CountryOfDestination",
                ReplacedDocumentNr: "ItemDetails.CustomsDocuments.Details.ReplacedDocumentNr",
                ReplacedDocumentDate: "ItemDetails.CustomsDocuments.Details.ReplacedDocumentDate",
                TempExtensionNr: "ItemDetails.CustomsDocuments.Details.TempExtensionNr",
                TempDocSentTo: "ItemDetails.CustomsDocuments.Details.TempDocSentTo",
                ReceivedFrom: "ItemDetails.CustomsDocuments.Details.ReceivedFrom",
                ReleasedTo: "ItemDetails.CustomsDocuments.Details.ReleasedTo",
            },
            ItemSearch: {
                Filters: {
                    ItemId: "ItemDetails.CustomsDocuments.ItemSearch.Filters.ItemId",
                    Description: "ItemDetails.CustomsDocuments.ItemSearch.Filters.Description",
                    Sidemark: "ItemDetails.CustomsDocuments.ItemSearch.Filters.Sidemark",
                    Lot: "ItemDetails.CustomsDocuments.ItemSearch.Filters.Lot",
                    JobNr: "ItemDetails.CustomsDocuments.ItemSearch.Filters.JobNr",
                    Owner: "ItemDetails.CustomsDocuments.ItemSearch.Filters.Owner",
                },
                Grid: {
                    UniqueId: "ItemDetails.CustomsDocuments.ItemSearch.Grid.UniqueId",
                    PartsQty: "ItemDetails.CustomsDocuments.ItemSearch.Grid.PartsQty",
                    PiecesQty: "ItemDetails.CustomsDocuments.ItemSearch.Grid.PiecesQty",
                    Description: "ItemDetails.CustomsDocuments.ItemSearch.Grid.Description",
                    Sidemark: "ItemDetails.CustomsDocuments.ItemSearch.Grid.Sidemark",
                    LocationRef: "ItemDetails.CustomsDocuments.ItemSearch.Grid.LocationRef",
                    Lot: "ItemDetails.CustomsDocuments.ItemSearch.Grid.Lot",
                    CustomsStatus: "ItemDetails.CustomsDocuments.ItemSearch.Grid.CustomsStatus",
                    Artist: "ItemDetails.CustomsDocuments.ItemSearch.Grid.Artist",
                    Title: "ItemDetails.CustomsDocuments.ItemSearch.Grid.Title",
                    Volume: "ItemDetails.CustomsDocuments.ItemSearch.Grid.Volume",
                    Weight: "ItemDetails.CustomsDocuments.ItemSearch.Grid.Weight",
                    Value: "ItemDetails.CustomsDocuments.ItemSearch.Grid.Value",
                    GrossVolume: "ItemDetails.CustomsDocuments.ItemSearch.Grid.GrossVolume",
                    Category: "ItemDetails.CustomsDocuments.ItemSearch.Grid.Category",
                    DealerRef: "ItemDetails.CustomsDocuments.ItemSearch.Grid.DealerRef",
                    IsParent: "ItemDetails.CustomsDocuments.ItemSearch.Grid.IsParent",
                    PackageRef: "ItemDetails.CustomsDocuments.ItemSearch.Grid.PackageRef",
                    OwnerRef: "ItemDetails.CustomsDocuments.ItemSearch.Grid.OwnerRef",
                },
                Title: "ItemDetails.CustomsDocuments.ItemSearch.Title",
                SuccessTitle: "ItemDetails.CustomsDocuments.ItemSearch.SuccessTitle",
                SuccessText: "ItemDetails.CustomsDocuments.ItemSearch.SuccessText",
                NoItemsText: "ItemDetails.CustomsDocuments.ItemSearch.NoItemsText",
            },
        },
        Details: {
            UniqueId: "ItemDetails.Details.UniqueId",
            LineItemNumber: "ItemDetails.Details.LineItemNumber",
            Parent: "ItemDetails.Details.Parent",
            PackageNumber: "ItemDetails.Details.PackageNumber",
            PONumber: "ItemDetails.Details.PONumber",
            Dealer: "ItemDetails.Details.Dealer",
            Description: "ItemDetails.Details.Description",
            ItemsQty: "ItemDetails.Details.ItemsQty",
            PiecesQty: "ItemDetails.Details.PiecesQty",
            Condition: "ItemDetails.Details.Condition",
            Category: "ItemDetails.Details.Category",
            VendorPart: "ItemDetails.Details.VendorPart",
            Sidemark: "ItemDetails.Details.Sidemark",
            Lot: "ItemDetails.Details.Lot",
            Tag: "ItemDetails.Details.Tag",
            Packaging: "ItemDetails.Details.Packaging",
            PackageLabelNr: "ItemDetails.Details.PackageLabelNr",
            PackageDepth: "ItemDetails.Details.PackageDepth",
            PackageWidth: "ItemDetails.Details.PackageWidth",
            PackageHeight: "ItemDetails.Details.PackageHeight",
            PackingTypeTitle: "ItemDetails.Details.PackingTypeTitle",
            PackingType: "ItemDetails.Details.PackingType",
            PBO: "ItemDetails.Details.PBO",
            PackingDepth: "ItemDetails.Details.PackingDepth",
            PackingWidth: "ItemDetails.Details.PackingWidth",
            PackingHeight: "ItemDetails.Details.PackingHeight",
            ItemInfo: "ItemDetails.Details.ItemInfo",
            Artist: "ItemDetails.Details.Artist",
            Title: "ItemDetails.Details.Title",
            Materials: "ItemDetails.Details.Materials",
            Circa: "ItemDetails.Details.Circa",
            CountryOfOrigin: "ItemDetails.Details.CountryOfOrigin",
            Size: "ItemDetails.Details.Size",
            Depth: "ItemDetails.Details.Depth",
            Volume: "ItemDetails.Details.Volume",
            Width: "ItemDetails.Details.Width",
            GrossVolume: "ItemDetails.Details.GrossVolume",
            Height: "ItemDetails.Details.Height",
            Weight: "ItemDetails.Details.Weight",
            SquareFt: "ItemDetails.Details.SquareFt",
            GrossWeight: "ItemDetails.Details.GrossWeight",
            VolumeConvert: "ItemDetails.Details.VolumeConvert",
            Financials: "ItemDetails.Details.Financials",
            Value: "ItemDetails.Details.Value",
            Currency: "ItemDetails.Details.Currency",
            VAT: "ItemDetails.Details.VAT",
            AmountToPay: "ItemDetails.Details.AmountToPay",
            LocationTitle: "ItemDetails.Details.LocationTitle",
            Location: "ItemDetails.Details.Location",
            ScannedOn: "ItemDetails.Details.ScannedOn",
            TransportMode: "ItemDetails.Details.TransportMode",
            Room: "ItemDetails.Details.Room",
            HandlingType: "ItemDetails.Details.HandlingType",
            Units: "ItemDetails.Details.Units",
            UnitsLength: "ItemDetails.Details.UnitsLength",
            UnitsVolume: "ItemDetails.Details.UnitsVolume",
            UnitsWeight: "ItemDetails.Details.UnitsWeight",
            Attention: "ItemDetails.Details.Attention",
            Bonded: "ItemDetails.Details.Bonded",
            TA: "ItemDetails.Details.TA",
            InCrate: "ItemDetails.Details.InCrate",
            CITES: "ItemDetails.Details.CITES",
            ExportLicenseRequired: "ItemDetails.Details.ExportLicenseRequired",
            Calendar: "ItemDetails.Details.Calendar",
            CollectionOn: "ItemDetails.Details.CollectionOn",
            PackingOn: "ItemDetails.Details.PackingOn",
            ReceivedOn: "ItemDetails.Details.ReceivedOn",
            ReleaseOn: "ItemDetails.Details.ReleaseOn",
            DeliveryOn: "ItemDetails.Details.DeliveryOn",
            CalendarDone: "ItemDetails.Details.CalendarDone",
            ConditionNotes: "ItemDetails.Details.ConditionNotes",
            RegistarNotes: "ItemDetails.Details.RegistarNotes",
            Status: "ItemDetails.Details.Status",
            Shipping: "ItemDetails.Details.Shipping",
            Cataloguing: "ItemDetails.Details.Cataloguing",
            Auction: "ItemDetails.Details.Auction",
            Jewelry: "ItemDetails.Details.Jewelry",
            HandlingStatus: "ItemDetails.Details.HandlingStatus",
            HandlingDate: "ItemDetails.Details.HandlingDate",
            InCare: "ItemDetails.Details.InCare",
            Split: "ItemDetails.Details.Split",
            Assemble: "ItemDetails.Details.Assemble",
            CustomsStatus: "ItemDetails.Details.CustomsStatus",
            CustomsStatusDate: "ItemDetails.Details.CustomsStatusDate",
            CustomsEntry: "ItemDetails.Details.CustomsEntry",
            Disposition: "ItemDetails.Details.Disposition",
            ShippingCost: "ItemDetails.Details.ShippingCost",
            InsurancePremiun: "ItemDetails.Details.InsurancePremiun",
            HSCode: "ItemDetails.Details.HSCode",
            InsuredByClient: "ItemDetails.Details.InsuredByClient",
            ShippingTerms: "ItemDetails.Details.ShippingTerms",
            ShippingCostRef: "ItemDetails.Details.ShippingCostRef",
            PhotoshootDate: "ItemDetails.Details.PhotoshootDate",
            ShotBy: "ItemDetails.Details.ShotBy",
            IsCatalogued: "ItemDetails.Details.IsCatalogued",
            VatOnHammer: "ItemDetails.Details.VatOnHammer",
            VatRateOnHammer: "ItemDetails.Details.VatRateOnHammer",
            VatOnBuyer: "ItemDetails.Details.VatOnBuyer",
            VatRateOnBuyer: "ItemDetails.Details.VatRateOnBuyer",
            ArtistRoyalty: "ItemDetails.Details.ArtistRoyalty",
            HammerPrice: "ItemDetails.Details.HammerPrice",
            BuyerPremium: "ItemDetails.Details.BuyerPremium",
            Owner: "ItemDetails.Details.Owner",
            Buyer: "ItemDetails.Details.Buyer",
            Seller: "ItemDetails.Details.Seller",
            Movement: "ItemDetails.Details.Movement",
            Accessories: "ItemDetails.Details.Accessories",
            SplitButton: "ItemDetails.Details.SplitButton",
            AddPackagesButton: "ItemDetails.Details.AddPackagesButton",
            TransferredTo: "ItemDetails.Details.TransferredTo",
            SplitItem: {
                Title: "ItemDetails.Details.SplitItem.Title",
                Amount: "ItemDetails.Details.SplitItem.Amount",
                NumberOfItems: "ItemDetails.Details.SplitItem.NumberOfItems",
                NumberOfPieces: "ItemDetails.Details.SplitItem.NumberOfPieces",
                ChildNumber: "ItemDetails.Details.SplitItem.ChildNumber",
                Description: "ItemDetails.Details.SplitItem.Description",
                PartsQty: "ItemDetails.Details.SplitItem.PartsQty",
                PiecesQty: "ItemDetails.Details.SplitItem.PiecesQty",
                DefaultDescription: "ItemDetails.Details.SplitItem.DefaultDescription",
                KeepOriginalValues: "ItemDetails.Details.SplitItem.KeepOriginalValues",
            },
            AddPackages: {
                Title: "ItemDetails.Details.AddPackages.Title",
                Amount: "ItemDetails.Details.AddPackages.Amount",
                NumberOfItems: "ItemDetails.Details.AddPackages.NumberOfItems",
                NumberOfPieces: "ItemDetails.Details.AddPackages.NumberOfPieces",
                PackageNumber: "ItemDetails.Details.AddPackages.PackageNumber",
                Description: "ItemDetails.Details.AddPackages.Description",
                PartsQty: "ItemDetails.Details.AddPackages.PartsQty",
                PiecesQty: "ItemDetails.Details.AddPackages.PiecesQty",
                DefaultDescription: "ItemDetails.Details.AddPackages.DefaultDescription",
            },
        },
        ItemsGrid: {
            UniqueId: "ItemDetails.ItemsGrid.UniqueId",
            PartsQty: "ItemDetails.ItemsGrid.PartsQty",
            PiecesQty: "ItemDetails.ItemsGrid.PiecesQty",
            Description: "ItemDetails.ItemsGrid.Description",
            SideMark: "ItemDetails.ItemsGrid.SideMark",
            Location: "ItemDetails.ItemsGrid.Location",
            Lot: "ItemDetails.ItemsGrid.Lot",
            PackageType: "ItemDetails.ItemsGrid.PackageType",
            Volume: "ItemDetails.ItemsGrid.Volume",
            Weight: "ItemDetails.ItemsGrid.Weight",
            Value: "ItemDetails.ItemsGrid.Value",
            GrossVolume: "ItemDetails.ItemsGrid.GrossVolume",
            HasPhotos: "ItemDetails.ItemsGrid.HasPhotos",
            NumberTitle: "ItemDetails.ItemsGrid.NumberTitle",
            Delete: {
                Title: "ItemDetails.ItemsGrid.Delete.Title",
                Text: "ItemDetails.ItemsGrid.Delete.Text",
            }
        },
        LocationHistory: {
            Item: "ItemDetails.LocationHistory.Item",
            Description: "ItemDetails.LocationHistory.Description",
            Date: "ItemDetails.LocationHistory.Date",
            Type: "ItemDetails.LocationHistory.Type",
            Location: "ItemDetails.LocationHistory.Location",
            Username: "ItemDetails.LocationHistory.Username",
            Title: "ItemDetails.LocationHistory.Title",
        },
        PackageHistory: {
            Item: "ItemDetails.PackageHistory.Item",
            Description: "ItemDetails.PackageHistory.Description",
            Date: "ItemDetails.PackageHistory.Date",
            Type: "ItemDetails.PackageHistory.Type",
            Package: "ItemDetails.PackageHistory.Package",
            Username: "ItemDetails.PackageHistory.Username",
            Title: "ItemDetails.PackageHistory.Title",
        },
        Tabs: {
            Details: "ItemDetails.Tabs.Details",
            TransactionHistory: "ItemDetails.Tabs.TransactionHistory",
            LocationHistory: "ItemDetails.Tabs.LocationHistory",
            PackageHistory: "ItemDetails.Tabs.PackageHistory",
            CustomsDocuments: "ItemDetails.Tabs.CustomsDocuments",
            Conditions: "ItemDetails.Tabs.Conditions",
            ActionLog: "ItemDetails.Tabs.ActionLog",
        },
        TransactionHistory: {
            Item: "ItemDetails.TransactionHistory.Item",
            Description: "ItemDetails.TransactionHistory.Description",
            Type: "ItemDetails.TransactionHistory.Type",
            Transaction: "ItemDetails.TransactionHistory.Transaction",
            Scheduled: "ItemDetails.TransactionHistory.Scheduled",
            Completed: "ItemDetails.TransactionHistory.Completed",
            ClientRef: "ItemDetails.TransactionHistory.ClientRef",
            InvoiceNr: "ItemDetails.TransactionHistory.InvoiceNr",
            Status: "ItemDetails.TransactionHistory.Status",
            Username: "ItemDetails.TransactionHistory.Username",
            Title: "ItemDetails.TransactionHistory.Title",
        },
        Warnings: {
            NewDocumentOpened: "ItemDetails.Warnings.NewDocumentOpened",
            NewConditionOpened: "ItemDetails.Warnings.NewConditionOpened",
            QtyChangeInProgress: "ItemDetails.Warning.QtyChangeInProgress",
        },
        Title: "ItemDetails.Title",
        NextItem: "ItemDetails.NextItem",
        PreviousItem: "ItemDetails.PreviousItem",
    },
    Jobs: {
        Delete: {
            Title: "Jobs.Delete.Title",
            Text: "Jobs.Delete.Text",
        },
        Filters: {
            FileNumber: "Jobs.Filters.FileNumber",
            JobType: "Jobs.Filters.JobType",
            Transaction: "Jobs.Filters.Transaction",
            ClientRef: "Jobs.Filters.ClientRef",
            Client: "Jobs.Filters.Client",
            Shipper: "Jobs.Filters.Shipper",
            Consignee: "Jobs.Filters.Consignee",
            InvoiceNumber: "Jobs.Filters.InvoiceNumber",
            TrackingNumber: "Jobs.Filters.TrackingNumber",
            Trip: "Jobs.Filters.Trip",
            ShipmentRef: "Jobs.Filters.ShipmentRef",
            GroupageNumber: "Jobs.Filters.GroupageNumber",
            ObjectNumber: "Jobs.Filters.ObjectNumber",
            PO: "Jobs.Filters.PO",
            PORef: "Jobs.Filters.PORef",
            Status: "Jobs.Filters.Status",
            Division: "Jobs.Filters.Division",
            Username: "Jobs.Filters.Username",
            StartDate: "Jobs.Filters.StartDate",
            EndDate: "Jobs.Filters.EndDate",
        },
        Grid: {
            JobNumber: "Jobs.Grid.JobNumber",
            CreatedOn: "Jobs.Grid.CreatedOn",
            Division: "Jobs.Grid.Division",
            Contact: "Jobs.Grid.Contact",
            Client: "Jobs.Grid.Client",
            ClientRef: "Jobs.Grid.ClientRef",
            Username: "Jobs.Grid.Username",
            JobType: "Jobs.Grid.JobType",
            FileStatus: "Jobs.Grid.FileStatus",
            Route: "Jobs.Grid.Route",
            Revenue: "Jobs.Grid.Revenue",
            Toolbar: {
                NewJob: "Jobs.Grid.Toolbar.NewJob",
                QuickEntry: "Jobs.Grid.Toolbar.QuickEntry",
                QuickEntryNew: "Jobs.Grid.Toolbar.QuickEntry.New",
                QuickEntryUpdate: "Jobs.Grid.Toolbar.QuickEntry.Update",
                Reopen: "Jobs.Grid.Toolbar.Reopen",
                ExportToExcel: "Jobs.Grid.Toolbar.ExportToExcel",
                Delete: "Jobs.Grid.Toolbar.Delete",
                HideRevenue: "Jobs.Grid.Toolbar.HideRevenue",
                ShowRevenue: "Jobs.Grid.Toolbar.ShowRevenue",
                ShowClosed: "Jobs.Grid.Toolbar.ShowClosed",
                QuickFilters: {
                    RecentJobs: "Jobs.Grid.Toolbar.RecentJobs",
                    MyJobs: "Jobs.Grid.Toolbar.MyJobs",
                    OfficeOpen: "Jobs.Grid.Toolbar.OfficeOpen",
                    OpenJobs: "Jobs.Grid.Toolbar.OpenJobs",
                    PastDue: "Jobs.Grid.Toolbar.PastDue",
                    MostRecent: "Jobs.Grid.Toolbar.MostRecent",
                    Closed: "Jobs.Grid.Toolbar.Closed",
                    Completed: "Jobs.Grid.Toolbar.Completed",
                },
            },
        },
        New: {
            Title: "Jobs.NewJob.Title",
            JobType: "Jobs.NewJob.JobType",
            Client: "Jobs.NewJob.Client",
            Insurance: "Jobs.NewJob.Insurance",
            InsuranceRequired: "Jobs.NewJob.InsuranceRequired",
            DeclinedOn: "Jobs.NewJob.DeclinedOn",
            DeclinedBy: "Jobs.NewJob.DeclinedBy",
            Booking: "Jobs.NewJob.Booking",
            Booker: "Jobs.NewJob.Booker",
            BookerContact: "Jobs.NewJob.BookerContact",
        },
        QuickEntry: {
            Title: "QuickEntry.Title",
            LeaveWarning: "QuickEntry.LeaveWarning",
            Imports: {
                ImportFromJob: "QuickEntry.Imports.ImportFromJob",
                ImportFromXML: "QuickEntry.Imports.ImportFromXML",
                ImportFromExternal: "QuickEntry.Imports.ImportFromExternal",
            },
            Details: {
                JobDetails: "QuickEntry.Details.JobDetails",
                Route: "QuickEntry.Details.Route",
                JobType: "QuickEntry.Details.JobType",
                Client: "QuickEntry.Details.Client",
                Address: "QuickEntry.Details.Address",
                Contact: "QuickEntry.Details.Contact",
                ClientRef: "QuickEntry.Details.ClientRef",
                NextDivision: "QuickEntry.Details.NextDivision",
                BypassWarehouse: "QuickEntry.Details.BypassWarehouse",
            },
            Insurance: {
                Title: "QuickEntry.Insurance.Title",
                InsuranceRequired: "QuickEntry.Insurance.InsuranceRequired",
                CIF: "QuickEntry.Insurance.CIF",
                Value: "QuickEntry.Insurance.Value",
                DeclinedOn: "QuickEntry.Insurance.DeclinedOn",
                DeclinedBy: "QuickEntry.Insurance.DeclinedBy",
            },
            Invoice: {
                Title: "QuickEntry.Invoice.Title",
                CreateInvoice: "QuickEntry.Invoice.CreateInvoice",
                JobType: "QuickEntry.Invoice.JobType",
                TransportMode: "QuickEntry.Invoice.TransportMode",
                Description: "QuickEntry.Invoice.Description",
            },
            Notes: {
                Title: "QuickEntry.Notes.Title",
                FileReminder: "QuickEntry.Notes.FileReminder",
                Comments: "QuickEntry.Notes.Comments",
            },
            Shipping: {
                Title: "QuickEntry.Shipping.Title",
                Shipper: "QuickEntry.Shipping.Shipper",
                ShipperAddress: "QuickEntry.Shipping.ShipperAddress",
                ShipperContact: "QuickEntry.Shipping.ShipperContact",
                Consignee: "QuickEntry.Shipping.Consignee",
                ConsigneeAddress: "QuickEntry.Shipping.ConsigneeAddress",
                ConsigneeContact: "QuickEntry.Shipping.ConsigneeContact",
            },
            Transactions: {
                Title: "QuickEntry.Transactions.Title",
                Collection: "QuickEntry.Transactions.Collection",
                Receivein: "QuickEntry.Transactions.Receivein",
                Division: "QuickEntry.Transactions.Division",
                Scheduled: "QuickEntry.Transactions.Scheduled",
                More: "QuickEntry.Transactions.More",
                Trip: "QuickEntry.Transactions.Trip",
                FixedFlag: "QuickEntry.Transactions.FixedFlag",
                Resource: "QuickEntry.Transactions.Resource",
                Tariff: "QuickEntry.Transactions.Tariff",
                TariffRange: "QuickEntry.Transactions.TariffRange",
                Charge: "QuickEntry.Transactions.Charge",
                UseZoneTariff: "QuickEntry.Transactions.UseZoneTariff",
                Instructions: "QuickEntry.Transactions.Instructions",
                DRDelivery: "QuickEntry.Transactions.DRDelivery",
                DRRelease: "QuickEntry.Transactions.DRRelease",
                DRDivision: "QuickEntry.Transactions.DRDivision",
                DRScheduled: "QuickEntry.Transactions.DRScheduled",
                ZoneWarning: "QuickEntry.Transactions.ZoneWarning",
                PPacking: "QuickEntry.Transactions.PPacking",
            },
            Items: {
                Grid: {
                    UniqueId: "QuickEntry.Items.Grid.UniqueId",
                    PartsQty: "QuickEntry.Items.Grid.PartsQty",
                    PiecesQty: "QuickEntry.Items.Grid.PiecesQty",
                    Description: "QuickEntry.Items.Grid.Description",
                    Location: "QuickEntry.Items.Grid.Location",
                    Lot: "QuickEntry.Items.Grid.Lot",
                    SideMark: "QuickEntry.Items.Grid.SideMark",
                    Volume: "QuickEntry.Items.Grid.Volume",
                    Value: "QuickEntry.Items.Grid.Value",
                    GrossVolume: "QuickEntry.Items.Grid.GrossVolume",
                    Artist: "QuickEntry.Items.Grid.Artist",
                    Title: "QuickEntry.Items.Grid.Title",
                    Pbo: "QuickEntry.Items.Grid.Pbo",
                    Depth: "QuickEntry.Items.Grid.Depth",
                    Width: "QuickEntry.Items.Grid.Width",
                    Height: "QuickEntry.Items.Grid.Height",
                    Weight: "QuickEntry.Items.Grid.Weight",
                    Category: "QuickEntry.Items.Grid.Category",
                },
                DeleteWarning: "QuickEntry.Items.DeleteWarning",
            },
            NoZoneTariffFound: "QuickEntry.Transactions.NoZoneTariffFound",
        },
    },
    Kendo: {
        Dropdown: {
            NoData: "dropdowns.nodata",
            Selected: "dropdowns.itemsSelected",
        },
    },
    Management: {
        BillableServices: {
            Delete: {
                Title: "Management.BillableServices.Delete.Title",
                Text: "Management.BillableServices.Delete.Text",
            },
            Filters: {
                Description: "Management.BillableServices.Filters.Description",
                Division: "Management.BillableServices.Filters.Division",
                Transaction: "Management.BillableServices.Filters.Transaction",
            },
            Grid: {
                Division: "Management.BillableServices.Grid.Division",
                JobType: "Management.BillableServices.Grid.JobType",
                TransportMode: "Management.BillableServices.Grid.TransportMode",
                Rate: "Management.BillableServices.Grid.Rate",
                UOM: "Management.BillableServices.Grid.UOM",
                VoxmeTransaction: "Management.BillableServices.Grid.VoxmeTransaction",
                Context: "Management.BillableServices.Grid.Context",
                CodeDescription: "Management.BillableServices.Grid.CodeDescription",
                TransctionDescription: "Management.BillableServices.Grid.TransctionDescription",
                Tariff: "Management.BillableServices.Grid.Tariff",
                GL: "Management.BillableServices.Grid.GL",
                TaxStatus: "Management.BillableServices.Grid.TaxStatus",
                TaxType: "Management.BillableServices.Grid.TaxType",
            },
        },
        Companies: {
            Delete: {
                Title: "Management.Companies.Delete.Title",
                Text: "Management.Companies.Delete.Text",
            },
            Filters: {
                CodeName: "Management.Companies.Filters.CodeName",
                Address: "Management.Companies.Filters.Address",
                Street: "Management.Companies.Filters.Street",
                City: "Management.Companies.Filters.City",
                Country: "Management.Companies.Filters.Country",
                ZIP: "Management.Companies.Filters.ZIP",
                State: "Management.Companies.Filters.State",
                Status: "Management.Companies.Filters.Status",
                StatusAll: "Management.Companies.Filters.Status.All",
                StatusActive: "Management.Companies.Filters.Status.Active",
                StatusDeactivated: "Management.Companies.Filters.Status.Deactivated",
            },
            Grid: {
                Code: "Management.Companies.Grid.Code",
                LegalName: "Management.Companies.Grid.LegalName",
                Type: "Management.Companies.Grid.Type",
                Category: "Management.Companies.Grid.Category",
                Warning: "Management.Companies.Grid.Warning",
                BillingInstructions: "Management.Companies.Grid.BillingInstructions",
                BadPayer: "Management.Companies.Grid.BadPayer",
                Deactivated: "Management.Companies.Grid.Deactivated",
                Toolbar: {
                    NewCompany: "Management.Companies.Grid.Toolbar.NewCompany",
                },
            },
        },
        Configuration: {
            Delete: {
                Title: "Management.Configuration.Delete.Title",
                Text: "Management.Configuration.Delete.Text",
            },
            Filters: {
                ItemType: "Management.Configuration.Filters.ItemType",
            },
            Grid: {
                Code: "Management.Configuration.Grid.Code",
                Description: "Management.Configuration.Grid.Description",
                StringValue: "Management.Configuration.Grid.StringValue",
                NumericValue: "Management.Configuration.Grid.NumericValue",
                Divisions: "Management.Configuration.Grid.Divisions",
                Toolbar: {
                    Warning: "Management.Configuration.Grid.Toolbar.Warning",
                },
            },
        },
        ExcelFields: {
            Delete: {
                Title: "Management.ExcelFields.Delete.Title",
                Text: "Management.ExcelFields.Delete.Text",
            },
            Grid: {
                FieldName: "Management.ExcelFields.Grid.FieldName",
                FieldType: "Management.ExcelFields.Grid.FieldType",
                Concatenate: "Management.ExcelFields.Grid.Concatenate",
            },
        },
        ExcelFieldsSources: {
            Delete: {
                Title: "Management.ExcelFieldSources.Delete.Title",
                Text: "Management.ExcelFieldSources.Delete.Text",
            },
            Grid: {
                FieldSourceName: "Management.ExcelFieldSources.Grid.FieldSourceName",
                HeaderType: "Management.ExcelFieldSources.Grid.HeaderType",
            },
        },
        ExcelSources: {
            Delete: {
                Title: "Management.ExcelSources.Delete.Title",
                Text: "Management.ExcelSources.Delete.Text",
            },
            Grid: {
                SourceName: "Management.ExcelSources.Grid.SourceName",
                TableInv: "Management.ExcelSources.Grid.TableInv",
                TableGeneralInfo: "Management.ExcelSources.Grid.TableGeneralInfo",
                TableSkids: "Management.ExcelSources.Grid.TableSkids",
                FieldEmfId: "Management.ExcelSources.Grid.FieldEmfId",
                ItemType: "Management.ExcelSources.Grid.ItemType",
                SkidType: "Management.ExcelSources.Grid.SkidType",
                ShipmentType: "Management.ExcelSources.Grid.ShipmentType",
                MaxRecords: "Management.ExcelSources.Grid.MaxRecords",
            },
        },
        ExtendedDims: {
            Delete: {
                Title: "Management.ExtendedDims.Delete.Title",
                Text: "Management.ExtendedDims.Delete.Text",
            },
            Filters: {
                HandlingType: "Management.ExtendedDims.Filters.HandlingType",
                ItemCategory: "Management.ExtendedDims.Filters.ItemCategory",
            },
            Grid: {
                HandlingType: "Management.ExtendedDims.Grid.HandlingType",
                ItemCategory: "Management.ExtendedDims.Grid.ItemCategory",
                Depth: "Management.ExtendedDims.Grid.Depth",
                Width: "Management.ExtendedDims.Grid.Width",
                Height: "Management.ExtendedDims.Grid.Height",
                OverheadPercentage: "Management.ExtendedDims.Grid.OverheadPercentage",
            },
        },
        ItemConfig: {
            Delete: {
                Title: "Management.ItemConfig.Delete.Title",
                Text: "Management.ItemConfig.Delete.Text",
            },
            Filters: {
                ItemName: "Management.ItemConfig.Filters.ItemName",
            },
            Grid: {
                ItemName: "Management.ItemConfig.Grid.ItemName",
                Description: "Management.ItemConfig.Grid.Description",
                Volume: "Management.ItemConfig.Grid.Volume",
                Weight: "Management.ItemConfig.Grid.Weight",
                Category: "Management.ItemConfig.Grid.Category",
                PackingType: "Management.ItemConfig.Grid.PackingType",
            }
        },
        Packing: {
            Delete: {
                Title: "Management.Packing.Delete.Title",
                Text: "Management.Packing.Delete.Text",
            },
            Grid: {
                Name: "Management.Packing.Grid.Name",
                Depth: "Management.Packing.Grid.Depth",
                Width: "Management.Packing.Grid.Width",
                Height: "Management.Packing.Grid.Height",
                Volume: "Management.Packing.Grid.Volume",
                Weight: "Management.Packing.Grid.Weight",
                Costs: "Management.Packing.Grid.Costs",
                PriceType: "Management.Packing.Grid.PriceType",
            },
        },
        Resources: {
            Delete: {
                Title: "ResourceDetails.Blackouts.Delete.Title",
                Text: "ResourceDetails.Blackouts.Delete.Text",
            },
            Details: {
                StartDate: "ResourceDetails.Blackouts.StartDate",
                EndDate: "ResourceDetails.Blackouts.EndDate",
                Reason: "ResourceDetails.Blackouts.Reason",
                Divisions: "ResourceDetails.Details.Divisions",
                ResourceName: "ResourceDetails.Details.ResourceName",
                HourCost: "ResourceDetails.Details.HourCost",
                ResourceType: "ResourceDetails.Details.ResourceType",
                HomeDivision: "ResourceDetails.Details.HomeDivision",
                Capacity: "ResourceDetails.Details.Capacity",
                Depth: "ResourceDetails.Details.Depth",
                Width: "ResourceDetails.Details.Width",
                Height: "ResourceDetails.Details.Height",
                Volume: "ResourceDetails.Details.Volume",
                Tonnage: "ResourceDetails.Details.Tonnage",
                Qualifications: "ResourceDetails.Details.Qualifications",
                UsedFor: "ResourceDetails.Details.UsedFor",
            },
            Filters: {
                HomeDivision: "Management.Resources.Filters.HomeDivision",
            },
        },
        Roles: {
            Grid: {
                Name: "Management.Roles.Grid.Name",
                Description: "Management.Roles.Grid.Description",
            },
            New: {
                CloneRole: "Management.Roles.NewRole.CloneRole",
                NewRole: "Management.Roles.NewRole.NewRole",
                Name: "Management.Roles.NewRole.Name",
                Description: "Management.Roles.NewRole.Description",
            },
        },
        SupplierCosts: {
            Delete: {
                Title: "Management.SupplierCost.Delete.Title",
                Text: "Management.SupplierCost.Delete.Text",
            },
            Grid: {
                CostRef: "Management.SupplierCost.Grid.CostRef",
                Supplier: "Management.SupplierCost.Grid.Supplier",
                SupplierRole: "Management.SupplierCost.Grid.SupplierRole",
                Option1: "Management.SupplierCost.Grid.Option1",
                Rate1: "Management.SupplierCost.Grid.Rate1",
                Option2: "Management.SupplierCost.Grid.Option2",
                Rate2: "Management.SupplierCost.Grid.Rate2",
                Option3: "Management.SupplierCost.Grid.Option3",
                Rate3: "Management.SupplierCost.Grid.Rate3",
            },
        },
        SupplierRoutes: {
            Delete: {
                Title: "Management.SupplierRoute.Delete.Title",
                Text: "Management.SupplierRoute.Delete.Text",
            },
            Grid: {
                OriginCity: "Management.SupplierRoute.Grid.OriginCity",
                OriginState: "Management.SupplierRoute.Grid.OriginState",
                OriginZip: "Management.SupplierRoute.Grid.OriginZip",
                DestCity: "Management.SupplierRoute.Grid.DestCity",
                DestState: "Management.SupplierRoute.Grid.DestState",
                DestZip: "Management.SupplierRoute.Grid.DestZip",
            },
        },
        SystemUsers: {
            Filters: {
                Tenant: "Management.SystemUsers.Filters.Tenant",
                User: "Management.SystemUsers.Filters.User",
            },
            Grid: {
                Username: "Management.SystemUsers.Grid.Username",
                Person: "Management.SystemUsers.Grid.Person",
            },
        },
        Tabs: {
            Companies: "Management.Tabs.Companies",
            SystemUsers: "Management.Tabs.SystemUsers",
            Roles: "Management.Tabs.Roles",
            Users: "Management.Tabs.Users",
            Resources: "Management.Tabs.Resources",
            RatesServices: "Management.Tabs.RatesServices",
            AuditTrail: "Management.Tabs.AuditTrail",
            Quote: "Management.Tabs.Quote",
            Report: "Management.Tabs.Report",
            BulkReport: "Management.Tabs.BulkReport",
            BillableSerivces: "Management.Tabs.BillableSerivces",
            PackingTypes: "Management.Tabs.PackingTypes",
            SupplierCost: "Management.Tabs.SupplierCost",
            ExcelSources: "Management.Tabs.ExcelSources",
            ExternalApps: "Management.Tabs.ExternalApps",
            MailConfig: "Management.Tabs.MailConfig",
            ItemConfig: "Management.Tabs.ItemConfig",
            ExtendedDims: "Management.Tabs.ExtendedDims",
            Configuration: "Management.Tabs.Configuration",
            NotificationExclusion: "Management.Tabs.NotificationExclusion",
            NotificationConfig: "Management.Tabs.NotificationConfig",
            AppSettings: "Management.Tabs.AppSettings",
        },
        Users: {
            Filters: {
                CodeName: "Management.Users.Filters.CodeName",
                Division: "Management.Users.Filters.Division",
                Type: "Management.Users.Filters.Type",
            },
            Grid: {
                Username: "Management.Users.Grid.Username",
                Person: "Management.Users.Grid.Person",
                Division: "Management.Users.Grid.Division",
                IsSystem: "Management.Users.Grid.IsSystem",
            },
        },
    },
    Navigation: {
        ClientFile: {
            GeneralInfo: "Navigation.ClientFile.GeneralInfo",
            POItems: "Navigation.ClientFile.POItems",
            Transactions: "Navigation.ClientFile.Transactions",
            Invoices: "Navigation.ClientFile.Invoices",
            Surveys: "Navigation.ClientFile.Surveys",
            Quotations: "Navigation.ClientFile.Quotations",
            Shipments: "Navigation.ClientFile.Shipments",
            Inventory: "Navigation.ClientFile.Inventory",
            Projects: "Navigation.ClientFile.Projects",
            Expenses: "Navigation.ClientFile.Expenses",
            Timesheets: "Navigation.ClientFile.Timesheets",
            Tasks: "Navigation.ClientFile.Tasks",
        },
        SideNav: {
            Financials: "Navigation.Financials",
            JobControl: "Navigation.JobControl",
            Shipping: "Navigation.Shipping",
            Inventory: "Navigation.Inventory",
            Operations: "Navigation.Operations",
            Trips: "Navigation.Trips",
            Surveys: "Navigation.Surveys",
            Quotations: "Navigation.Quotations",
            CRM: "Navigation.CRM",
            Tasks: "Navigation.Tasks",
            Warehouses: "Navigation.Warehouses",
            ReusableCrates: "Navigation.ReusableCrates",
            Management: "Navigation.Management",
        },
    },
    NewParty: {
        Address: {
            AddressType: "NewParty.Address.AddressType",
            City: "NewParty.Address.City",
            Comments: "NewParty.Address.Comments",
            Country: "NewParty.Address.Country",
            DefaultFor: "NewParty.Address.DefaultFor",
            DefaultForBilling: "NewParty.Address.DefaultForBilling",
            DefaultForMailing: "NewParty.Address.DefaultForMailing",
            DefaultForShipping: "NewParty.Address.DefaultForShipping",
            State: "NewParty.Address.State",
            Street1: "NewParty.Address.Street1",
            Street2: "NewParty.Address.Street2",
            Street3: "NewParty.Address.Street3",
            Title: "NewParty.Address.Title",
            UseFor: "NewParty.Address.UseFor",
            UseForMailing: "NewParty.Address.UseForMailing",
            UseForBilling: "NewParty.Address.UseForBilling",
            UseForShipping: "NewParty.Address.UseForShipping",
            Zip: "NewParty.Address.ZIP",
        },
        Contact: {
            Email: "NewParty.Contact.Email",
            Fax: "NewParty.Contact.Fax",
            Mobile: "NewParty.Contact.Mobile",
            Phone: "NewParty.Contact.Phone",
            Title: "NewParty.Contact.Title",
            Web: "NewParty.Contact.Web",
            UseFor: "NewParty.Contact.ContactUseFor",
            UseForBilling: "NewParty.Contact.ContactForBilling",
            UseForGeneral: "NewParty.Contact.ContactForGeneral",
            UseForMailing: "NewParty.Contact.ContactForMailing",
            UseForShipping: "NewParty.Contact.ContactForShipping",
        },
        Party: {
            Code: "NewParty.Party.Code",
            LegalName: "NewParty.Party.LegalName",
            PartyType: "NewParty.Party.PartyType",
        },
        Person: {
            Contact: {
                Email: "NewParty.Person.Contact.Email",
                Fax: "NewParty.Person.Contact.Fax",
                Mobile: "NewParty.Person.Contact.Mobile",
                Phone: "NewParty.Person.Contact.Phone",
                Title: "NewParty.Person.Contact.Title",
            },
            FirstName: "NewParty.Person.FirstName",
            IsBillingType: "NewParty.Person.IsBillingType",
            IsGeneralType: "NewParty.Person.IsGeneralType",
            IsMailingType: "NewParty.Person.IsMailingType",
            IsShippingType: "NewParty.Person.IsShippingType",
            LastName: "NewParty.Person.LastName",
            Role: "NewParty.Person.Role",
            Salutation: "NewParty.Person.Salutation",
            Title: "NewParty.Person.Title",
        },
        Titles: {
            Agent: "NewParty.Titles.Agent",
            BilledTo: "NewParty.Titles.BilledTo",
            Booker: "NewParty.Titles.Booker",
            Carrier: "NewParty.Titles.Carrier",
            Client: "NewParty.Titles.Client",
            Company: "NewParty.Titles.Company",
            Consignee: "NewParty.Titles.Consignee",
            Dealer: "NewParty.Titles.Dealer",
            Shipper: "NewParty.Titles.Shipper",
            Trucker: "NewParty.Titles.Trucker",
            Vendor: "NewParty.Titles.Vendor",
            Owner: "NewParty.Titles.Owner",
            Buyer: "NewParty.Titles.Buyer",
            Seller: "NewParty.Titles.Seller",
        },
    },
    Operations: {
        Filters: {
            StartDate: "Operations.Filters.StartDate",
            EndDate: "Operations.Filters.EndDate",
            Users: "Operations.Filters.Users",
            Divisions: "Operations.Filters.Divisions",
            Resources: "Operations.Filters.Resources",
            Status: "Operations.Filters.Status",
            Type: "Operations.Filters.Type",
            JobType: "Operations.Filters.JobType",
            JobId: "Operations.Filters.JobId",
            Trip: "Operations.Filters.Trip",
            Address: "Operations.Filters.Address",
            ShowAll: "Operations.Filters.ShowAll",
            HideLinkedToTrips: "Operations.Filters.HideLinkedToTrips",
        },
        Grid: {
            JobType: "Operations.Grid.JobType",
            JobId: "Operations.Grid.JobId",
            TransactionType: "Operations.Grid.TransactionType",
            InternalId: "Operations.Grid.InternalId",
            Status: "Operations.Grid.Status",
            FixedFlag: "Operations.Grid.FixedFlag",
            Resources: "Operations.Grid.Resources",
            Division: "Operations.Grid.Division",
            CreationDate: "Operations.Grid.CreationDate",
            ScheduledDate: "Operations.Grid.ScheduledDate",
            ActualDate: "Operations.Grid.ActualDate",
            OwnedBy: "Operations.Grid.OwnedBy",
            StaffNr: "Operations.Grid.StaffNr",
            Instructions: "Operations.Grid.Instructions",
            Shipper: "Operations.Grid.Shipper",
            Street: "Operations.Grid.Street",
            City: "Operations.Grid.City",
            ZIP: "Operations.Grid.ZIP",
            Consignee: "Operations.Grid.Consignee",
            TripNr: "Operations.Grid.TripNr",
            NeededDate: "Operations.Grid.NeededDate",
            Toolbar: {
                PrintLabels: "Operations.Grid.Toolbar.PrintLabels",
                Scheduling: "Operations.Grid.Toolbar.Scheduling",
                SetStatus: "Operations.Grid.Toolbar.SetStatus",
                Map: "Operations.Grid.Toolbar.Map",
                Route: "Operations.Grid.Toolbar.Route",
                Calendar: "Operations.Grid.Toolbar.Calendar",
                AddToCalendar: "Operations.Grid.Toolbar.AddToCalendar",
            },
        },
        SetStatus: {
            Title: "Operations.SetStatus.Title",
            Status: "Operations.SetStatus.Status",
            AcknowledgedBy: "Operations.SetStatus.AcknowledgedBy",
            Scheduled: "Operations.SetStatus.Scheduled",
            Actual: "Operations.SetStatus.Actual",
            Arrival: "Operations.SetStatus.Arrival",
            Departure: "Operations.SetStatus.Departure",
            DateWarning: "Operations.SetStatus.DateWarning",
        },
    },
    PartySearch: {
        Filters: {
            City: "PartySearch.Filters.City",
            Code: "PartySearch.Filters.Code",
            Country: "PartySearch.Filters.Country",
            Name: "PartySearch.Filters.Name",
            State: "PartySearch.Filters.State",
            Street: "PartySearch.Filters.Street",
            Zip: "PartySearch.Filters.ZIP",
        },
        Grid: {
            City: "PartySearch.Grid.City",
            Code: "PartySearch.Grid.Code",
            Country: "PartySearch.Grid.Country",
            LegalName: "PartySearch.Grid.LegalName",
            State: "PartySearch.Grid.State",
            Street: "PartySearch.Grid.Street",
            Zip: "PartySearch.Grid.ZIP",
        },
        Title: "PartySearch.Title",
    },
    PO: {
        Delete: {
            Title: "PO.Delete.Title",
            Text: "PO.Delete.Text",
        },
        Details: {
            Dealer: {
                AmountToPay: "PO.Parameters.AmountToPay",
                ChequeNumber: "PO.Parameters.ChequeNumber",
                Currency: "PO.Parameters.Currency",
                Dealer: "PO.Parameters.Dealer",
                DealerAddress: "PO.Parameters.DealerAddress",
                DealerContact: "PO.Parameters.DealerContact",
            },
            Items: {
                Delete: {
                    Title: "PO.Items.Delete.Title",
                    Text: "PO.Items.Delete.Text",
                },
                Grid: {
                    UniqueId: "PO.Items.UniqueId",
                    PartsQty: "PO.Items.PartsQty",
                    PiecesQty: "PO.Items.PiecesQty",
                    Description: "PO.Items.Description",
                    SideMark: "PO.Items.SideMark",
                    Location: "PO.Items.Location",
                    Lot: "PO.Items.Lot",
                    Artist: "PO.Items.Artist",
                    Title: "PO.Items.Title",
                    PackageType: "PO.Items.PackageType",
                    Volume: "PO.Items.Volume",
                    Weight: "PO.Items.Weight",
                    Value: "PO.Items.Value",
                    GrossVolume: "PO.Items.GrossVolume",
                    Category: "PO.Items.Category",
                    HasPhotos: "PO.Items.HasPhotos",
                },
                Toolbar: {
                    NewItem: "PO.Items.Toolbar.NewItem",
                    Clone: "PO.Items.Toolbar.Clone",
                    Delete: "PO.Items.Toolbar.Delete",
                    ImportFromExcel: "PO.Items.Toolbar.ImportFromExcel",
                    SetOwner: "PO.Items.Toolbar.SetOwner",
                    GenerateIds: "PO.Items.Toolbar.GenerateIds",
                },
            },
            Properties: {
                PONumber: "PO.Properties.PONumber",
                Reference: "PO.Properties.Reference",
                CreatedOn: "PO.Properties.CreatedOn",
                Status: "PO.Properties.Status",
                Client: "PO.Properties.Client",
                Division: "PO.Properties.Division",
                OwnedBy: "PO.Properties.OwnedBy",
            },
            NewItem: {
                Title: "PO.Items.NewItem.Title",
                Description: "PO.Items.NewItem.Description",
            },
        },
        Filters: {
            PONumber: "PO.Filters.PONumber",
            Dealer: "PO.Filters.Dealer",
            Item: "PO.Filters.Item",
        },
        Grid: {
            PONumber: "PO.Grid.PONumber",
            CreatedOn: "PO.Grid.CreatedOn",
            Status: "PO.Grid.Status",
            Dealer: "PO.Grid.Dealer",
            Reference: "PO.Grid.Reference",
            Comments: "PO.Grid.Comments",
            Toolbar: {
                New: "PO.Grid.Toolbar.NewPO",
            },
        },
        NewPO: {
            Title: "PO.NewPO.Title",
            Dealer: "PO.NewPO.Dealer",
        }
    },
    Projects: {
        Delete: {
            Title: "Projects.Delete.Title",
            Text: "Projects.Delete.Text",
        },
        Filters: {
            StartDate: "Projects.Filters.StartDate",
            EndDate: "Projects.Filters.EndDate",
            ProjectNr: "Projects.Filters.ProjectNr",
            Surveyor: "Projects.Filters.Surveyor",
            ClientCode: "Projects.Filters.ClientCode",
            ClientName: "Projects.Filters.ClientName",
        },
        Grid: {
            UniqueId: "Quotations.ProjectsGrid.UniqueId",
            Status: "Quotations.ProjectsGrid.Status",
            Description: "Quotations.ProjectsGrid.Description",
            StartDate: "Quotations.ProjectsGrid.StartDate",
            CompletionDate: "Quotations.ProjectsGrid.CompletionDate",
            ClientRef1: "Quotations.ProjectsGrid.ClientRef1",
            ClientRef2: "Quotations.ProjectsGrid.ClientRef2",
            Toolbar: {
                NewProject: "Projects.Grid.Toolbar.NewProject",
            },
        },
    },
    Quotations: {
        Delete: {
            Title: "Quotations.Delete.Title",
            Text: "Quotations.Delete.Text",
        },
        Filters: {
            ClientCode: "Quotations.Filters.ClientCode",
            ClientName: "Quotations.Filters.ClientName",
            ClientRef: "Quotations.Filters.ClientRef",
            QuotationNr: "Quotations.Filters.QuotationNr",
            Project: "Quotations.Filters.Project",
            Description: "Quotations.Filters.Description",
            ExpenseDetails: "Quotations.Filters.ExpenseDetails",
            Division: "Quotations.Filters.Division",
            User: "Quotations.Filters.User",
            Status: "Quotations.Filters.Status",
            StartDate: "Quotations.Filters.StartDate",
            EndDate: "Quotations.Filters.EndDate",
            ItemObjectNr: "Quotations.Filters.ItemObjectNr",
            Lot: "Quotations.Filters.Lot",
            CustomsEntryNr: "Quotations.Filters.CustomsEntryNr",
            SideMark: "Quotations.Filters.SideMark",
            ItemDescription: "Quotations.Filters.ItemDescription",
        },
        Grid: {
            UniqueId: "Quotations.Grid.UniqueId",
            Description: "Quotations.Grid.Description",
            Total: "Quotations.Grid.Total",
            Created: "Quotations.Grid.Created",
            ClientRef: "Quotations.Grid.ClientRef",
            Job: "Quotations.Grid.Job",
            Project: "Quotations.Grid.Project",
            ClientName: "Quotations.Grid.ClientName",
            ClientCode: "Quotations.Grid.ClientCode",
            CreatedBy: "Quotations.Grid.CreatedBy",
            ShipperCity: "Quotations.Grid.ShipperCity",
            ShipperCountry: "Quotations.Grid.ShipperCountry",
            ConsigneeCity: "Quotations.Grid.ConsigneeCity",
            ConsigneeCountry: "Quotations.Grid.ConsigneeCountry",
            Toolbar: {
                New: "Quotations.Grid.Toolbar.NewQuotation",
            },
        },
        New: {
            Title: "Quotations.NewQuotation.Title",
            Client: "Quotations.NewQuotation.Client",
            ClientContact: "Quotations.NewQuotation.ClientContact",
            Shipper: "Quotations.NewQuotation.Shipper",
            ShipperContact: "Quotations.NewQuotation.ShipperContact",
            JobType: "Quotations.NewQuotation.JobType",
            Currency: "Quotations.NewQuotation.Currency",
            Status: "Quotations.NewQuotation.Status",
        },
        Tabs: {
            Quotations: "Quotations.Tabs.Quotations",
            Projects: "Quotations.Tabs.Projects",
        },
    },
    Report: {
        Button: {
            Generate: "Report.Button.Generate",
        },
    },
    ReusableCrates: {
        Filters: {
            Depth: "ReusableCrates.Filters.Depth",
            Width: "ReusableCrates.Filters.Width",
            Height: "ReusableCrates.Filters.Height",
            Status: "ReusableCrates.Filters.Status",
            Job: "ReusableCrates.Filters.Job",
            Item: "ReusableCrates.Filters.Item",
            Package: "ReusableCrates.Filters.Package",
        },
        Grid: {
            LabelNr: "ReusableCrates.Grid.LabelNr",
            LoadedInto: "ReusableCrates.Grid.LoadedInto",
            PackageNr: "ReusableCrates.Grid.PackageNr",
            IsCrate: "ReusableCrates.Grid.IsCrate",
            Type: "ReusableCrates.Grid.Type",
            Depth: "ReusableCrates.Grid.Depth",
            Width: "ReusableCrates.Grid.Width",
            Height: "ReusableCrates.Grid.Height",
            Transactions: "ReusableCrates.Grid.Transactions",
        },
    },
    Shared: {
        Buttons: {
            Apply: "Shared.Buttons.Apply",
            Cancel: "Shared.Buttons.Cancel",
            ClearInput: "Shared.Buttons.ClearInput",
            Clone: "Shared.Buttons.Clone",
            CompanyDetails: "Shared.Buttons.CompanyDetails",
            Confirm: "Shared.Buttons.Confirm",
            Create: "Shared.Buttons.Create",
            Delete: "Shared.Buttons.Delete",
            Filter: "Shared.Buttons.Filter",
            ImportFromExcel: "Shared.Buttons.ImportFromExcel",
            New: "Shared.Buttons.New",
            PartySearch: "Shared.Buttons.PartySearch",
            PersonDetails: "Shared.Buttons.PersonDetails",
            Report: "Shared.Buttons.Report",
            Save: "Shared.Buttons.Save",
            SaveAndClose: "Shared.Buttons.SaveAndClose",
            ShowAll: "Shared.Buttons.ShowAll",
            Update: "Shared.Buttons.Update",
        },
        Common: {
            Error: "Shared.Common.Error",
            FilterLengthWarning: "Shared.Common.FilterLengthWarning",
            Warning: "Shared.Common.Warning",
            Yes: "Shared.Common.Yes",
            No: "Shared.Common.No",
            None: "Shared.Common.None",
        },
    },
    Shipping: {
        Delete: {
            Title: "Shipping.Delete.Title",
            Text: "Shipping.Delete.Text",
        },
        Details: {
            Comments: {
                ContentDescription: "ShipmentDetails.Comments.ContentDescription",
                Notes: "ShipmentDetails.Comments.Notes",
            },
            Containers: {
                Delete: {
                    Title: "ShipmentDetails.Containers.Delete.Title",
                    Text: "ShipmentDetails.Containers.Delete.Text",
                },
                New: "ShipmentDetails.Containers.Grid.New",
                ContainerType: "ShipmentDetails.Containers.Grid.ContainerType",
                LoadType: "ShipmentDetails.Containers.Grid.LoadType",
                SerialNumber: "ShipmentDetails.Containers.Grid.SerialNumber",
                SealNumber: "ShipmentDetails.Containers.Grid.SealNumber",
                GrossVolume: "ShipmentDetails.Containers.Grid.GrossVolume",
                GrossWeight: "ShipmentDetails.Containers.Grid.GrossWeight",
            },
            Dates: {
                ETD: "ShipmentDetails.Dates.ETD",
                ETA: "ShipmentDetails.Dates.ETA",
                DeliveryCutOff: "ShipmentDetails.Dates.DeliveryCutOff",
                DocumentsCutOff: "ShipmentDetails.Dates.DocumentsCutOff",
                DocumentFolder: "ShipmentDetails.Dates.DocumentFolder",
            },
            Groupages: {
                Grid: {
                    UniqueId: "ShipmentDetails.GroupageShipments.Grid.UniqueId",
                    Release: "ShipmentDetails.GroupageShipments.Grid.Release",
                    ETA: "ShipmentDetails.GroupageShipments.Grid.ETA",
                    ETD: "ShipmentDetails.GroupageShipments.Grid.ETD",
                    POL: "ShipmentDetails.GroupageShipments.Grid.POL",
                    POE: "ShipmentDetails.GroupageShipments.Grid.POE",
                    Reference: "ShipmentDetails.GroupageShipments.Grid.Reference",
                },
            },
            Parties: {
                Shipper: "ShipmentDetails.Parties.Shipper",
                Consignee: "ShipmentDetails.Parties.Consignee",
                Trucker: "ShipmentDetails.Parties.Trucker",
                Carrier: "ShipmentDetails.Parties.Carrier",
                CustomsOffice: "ShipmentDetails.Parties.CustomsOffice",
                CustomsAddress: "ShipmentDetails.Parties.CustomsAddress",
                ForwardingAgent: "ShipmentDetails.Parties.ForwardingAgent",
                ForwAgentAddress: "ShipmentDetails.Parties.ForwAgentAddress",
                DestinationAgent: "ShipmentDetails.Parties.DestinationAgent",
                DestinationAddress: "ShipmentDetails.Parties.DestinationAddress",
            },
            References: {
                Groupage: "ShipmentDetails.References.Groupage",
                GroupageDetails: "ShipmentDetails.References.GroupageDetails",
                Reference: "ShipmentDetails.References.Reference",
                ExternalRef: "ShipmentDetails.References.ExternalRef",
                Voyage: "ShipmentDetails.References.Voyage",
                Vessel: "ShipmentDetails.References.Vessel",
                HarmonizationCode: "ShipmentDetails.References.HarmonizationCode",
                POL: "ShipmentDetails.References.POL",
                POE: "ShipmentDetails.References.POE",
                POD: "ShipmentDetails.References.POD",
            },
        },
        Filters: {
            StartDate: "Shipping.Filters.StartDate",
            EndDate: "Shipping.Filters.EndDate",
            Reference: "Shipping.Filters.Reference",
            ContainerRef: "Shipping.Filters.ContainerRef",
        },
        Grid: {
            UniqueId: "Shipping.Grid.UniqueId",
            ETA: "Shipping.Grid.ETA",
            ETD: "Shipping.Grid.ETD",
            POL: "Shipping.Grid.POL",
            POE: "Shipping.Grid.POE",
            Reference: "Shipping.Grid.Reference",
            Toolbar: {
                New: "Shipping.Grid.Toolbar.NewShipment",
            },
        },
    },
    Surveys: {
        Delete: {
            Title: "Surveys.Delete.Title",
            Text: "Surveys.Delete.Text",
        },
        Filters: {
            StartDate: "Surveys.Filters.StartDate",
            EndDate: "Surveys.Filters.EndDate",
            SurveyNr: "Surveys.Filters.SurveyNr",
            Surveyor: "Surveys.Filters.Surveyor",
            ClientName: "Surveys.Filters.ClientName",
            ClientCode: "Surveys.Filters.ClientCode",
        },
        Grid: {
            UniqueId: "Surveys.Grid.UniqueId",
            Status: "Surveys.Grid.Status",
            SurveyDate: "Surveys.Grid.SurveyDate",
            ClientName: "Surveys.Grid.ClientName",
            ClientCode: "Surveys.Grid.ClientCode",
            ItemsNr: "Surveys.Grid.ItemsNr",
            TotalVolume: "Surveys.Grid.TotalVolume",
            TotalWeight: "Surveys.Grid.TotalWeight",
            Toolbar: {
                New: "Surveys.Grid.Toolbar.NewSurvey",
            },
        },
        New: {
            Title: "Surveys.NewSurvey.Title",
            Client: "Surveys.NewSurvey.Client",
            ClientContact: "Surveys.NewSurvey.ClientContact",
            Shipper: "Surveys.NewSurvey.Shipper",
            ShipperContact: "Surveys.NewSurvey.ShipperContact",
            Status: "Surveys.NewSurvey.Status",
            SurveyDate: "Surveys.NewSurvey.SurveyDate",
            EndDate: "Surveys.NewSurvey.EndDate",
        },
    },
    System: {
        Warnings: {
            ServerOperationWarning: "ServerOperationWarning.Text",
        }
    },
    Tasks: {
        TaskForm: {
            ClientFeedback: "Tasks.TaskForm.ClientFeedback",
            CreatedDate: "Tasks.TaskForm.CreatedDate",
            Details: "Tasks.TaskForm.Details",
            DueDate: "Tasks.TaskForm.DueDate",
            OwnedBy: "Tasks.TaskForm.OwnedBy",
            Priority: "Tasks.TaskForm.Priority",
            ReminderDate: "Tasks.TaskForm.ReminderDate",
            StartDate: "Tasks.TaskForm.StartDate",
            Status: "Tasks.TaskForm.Status",
            Subject: "Tasks.TaskForm.Subject",
            Title: "Tasks.TaskForm.Title",
            Type: "Tasks.TaskForm.Type",
        },
        Filters: {
            Description: "Tasks.Filters.Description",
            OwnedBy: "Tasks.Filters.OwnedBy",
            Status: "Tasks.Filters.Status",
            DateFrom: "Tasks.Filters.DateFrom",
            DateTo: "Tasks.Filters.DateTo",
            StartDate: "Tasks.Filters.StartDate",
            DueDate: "Tasks.Filters.DueDate",
        },
        Grid: {
            StartDate: "Tasks.Grid.StartDate",
            DueDate: "Tasks.Grid.DueDate",
            Owner: "Tasks.Grid.Owner",
            Subject: "Tasks.Grid.Subject",
            Priority: "Tasks.Grid.Priority",
            Status: "Tasks.Grid.Status",
            OpportunityDescription: "Tasks.Grid.OpportunityDescription",
            OpportunityCompany: "Tasks.Grid.OpportunityCompany",
            OpportunityContact: "Tasks.Grid.OpportunityContact",
            OriginalTask: "Tasks.Grid.OriginalTask",
            FollowUpTask: "Tasks.Grid.FollowUpTask",
            HasDocuments: "Tasks.Grid.HasDocuments",
        },
    },
    TripSearch: {
        Filters: {
            ServiceCode: "TripSearch.Filters.ServiceCode",
            TripName: "TripSearch.Filters.TripName",
            TripType: "TripSearch.Filters.TripType",
            Reference: "TripSearch.Filters.Reference",
            Description: "TripSearch.Filters.Description",
            LoadedBy: "TripSearch.Filters.LoadedBy",
        },
        Grid: {
            TripNumber: "TripSearch.Grid.TripNumber",
            ServiceCode: "TripSearch.Grid.ServiceCode",
            ServiceName: "TripSearch.Grid.ServiceName",
            TruckName: "TripSearch.Grid.TruckName",
            LoadedBy: "TripSearch.Grid.LoadedBy",
            Reference: "TripSearch.Grid.Reference",
            Description: "TripSearch.Grid.Description",
        },
        ServiceGrid: {
            Title: "TripSearch.ServiceGrid.Title",
            ServiceCode: "TripSearch.ServiceGrid.ServiceCode",
            ServiceName: "TripSearch.ServiceGrid.ServiceName",
        },
        TariffGrid: {
            Title: "TripSearch.TariffGrid.Title",
            TariffName: "TripSearch.TariffGrid.TariffName",
            Rate: "TripSearch.TariffGrid.Rate",
            Currency: "TripSearch.TariffGrid.Currency",
            MinimumRate: "TripSearch.TariffGrid.MinimumRate",
        },
        TariffRangeGrid: {
            Title: "TripSearch.TariffRangeGrid.Title",
            From: "TripSearch.TariffRangeGrid.From",
            To: "TripSearch.TariffRangeGrid.To",
            Rate: "TripSearch.TariffRangeGrid.Rate",
        },
        Title: "TripSearch.Title",
    },
    Warehouses: {
        LocationDelete: {
            Title: "Warehouses.LocationDelete.Title",
            Text: "Warehouses.LocaitonDelete.Text",
        },
        LocationForm: {
            Title: "Warehouses.LocationForm.Title",
            LocationID: "Warehouses.LocationForm.LocationID",
            LocationType: "Warehouses.LocationForm.LocationType",
            IsMobile: "Warehouses.LocationForm.IsMobile",
            WarehouseLocation: "Warehouses.LocationForm.WarehouseLocation",
            WarehouseZone: "Warehouses.LocationForm.WarehouseZone",
            WeightCapacity: "Warehouses.LocationForm.WeightCapacity",
            VolumeCapacity: "Warehouses.LocationForm.VolumeCapacity",
            SquareFootageRate: "Warehouses.LocationForm.SquareFootageRate",
            VolumeRate: "Warehouses.LocationForm.VolumeRate",
        },
        LocationGrid: {
            Type: "Warehouses.LocationGrid.Type",
            UniqueId: "Warehouses.LocationGrid.UniqueId",
            WarehousePosition: "Warehouses.LocationGrid.WarehousePosition",
            Description: "Warehouses.LocationGrid.Description",
            VolumeCapacity: "Warehouses.LocationGrid.VolumeCapacity",
            WeightCapacity: "Warehouses.LocationGrid.WeightCapacity",
            UtilizedVolume: "Warehouses.LocationGrid.UtilizedVolume",
            UtilizedWeight: "Warehouses.LocationGrid.UtilizedWeight",
            Toolbar: {
                Title: "Warehouses.LocationGrid.Toolbar.Title",
                FilterPlaceholder: "Warehouses.LocationGrid.Toolbar.FilterPlaceholder",
                NewLocationTitle: "Warehouses.LocationGrid.Toolbar.NewLocationTitle",
                EditLocationTitle: "Warehouses.LocationGrid.Toolbar.EditLocationTitle",
                DeleteLocation: "Warehouses.LocationGrid.Toolbar.DeleteLocation",
                Print: "Warehouses.LocationGrid.Toolbar.Print",
                Export: "Warehouses.LocationGrid.Toolbar.Export",
            },
        },
        WarehouseModal: {
            Title: "Warehouses.WarehouseForm.Title",
            Name: "Warehouses.WarehouseForm.Name",
            VolumeUnits: "Warehouses.WarehouseForm.VolumeUnits",
            WeightUnits: "Warehouses.WarehouseForm.WeightUnits",
            MaxInsuranceCoverage: "Warehouses.WarehouseForm.MaxInsuranceCoverage",
            Divisions: "Warehouses.WarehouseForm.Divisions",
        },
        WarehouseGrid: {
            Whname: "Warehouses.Grid.Whname",
            DivisionNames: "Warehouses.Grid.DivisionNames",
            TotalVolumeCapacity: "Warehouses.Grid.TotalVolumeCapacity",
            TotalWeightCapacity: "Warehouses.Grid.TotalWeightCapacity",
            MaxInsuranceCoverage: "Warehouses.Grid.MaxInsuranceCoverage",
            VolumeUtilization: "Warehouses.Grid.VolumeUtilization",
            WeightUtilization: "Warehouses.Grid.WeightUtilization",
            Toolbar: {
                Title: "Warehouses.Grid.Toolbar.Title",
                NewWarehouseTitle: "Warehouses.Grid.Toolbar.NewWarehouseTitle",
                EditWarehouseTitle: "Warehouses.Grid.Toolbar.EditWarehouseTitle",
            },
        },
    }
};

export default LocalizationKeys;